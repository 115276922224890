import dewarApi, { getDomain } from '../../services/dewarApi'

export const getServiceVeredicts = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/service_veredicts', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceVeredict = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/service_veredicts/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceVeredict = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/service_veredicts', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceVeredict = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/service_veredicts/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceVeredict = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/service_veredicts/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceVeredicts = async (name, token) => (
	fetch( getDomain()+`/service_veredicts?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_veredict => ({
				label: service_veredict.name,
				value: service_veredict.id,
			})),
		)
)