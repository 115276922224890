import React from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { handleError } from '../../../helpers'
import { deletePaymentReceipt } from '../services'

export default function PaymentReceiptsTable({ paymentReceipts, reload }) {
	const currentUser = useSelector(state => state.auth.user)

	const handleDelete = id => {
		deletePaymentReceipt(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id'
		},
		{
			title: 'Fecha de Registro',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Comentarios',
			dataIndex: 'comments',
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver Soporte">
						<a href={r.attachment_uri} target="_blank" rel="noreferrer">
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</a>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Eliminar Soporte">
								<Popconfirm 
									title="¿Desea eliminar el soporte de pago?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={paymentReceipts}
				columns={columns}
				rowKey={r => r.id}
				size='small'
			/>
		</React.Fragment>
	)
}