import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ServiceReviewPreview from './ServiceReviewPreview'

export default function ServiceReviews() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/service_reviews/preview/:id" component={ServiceReviewPreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}