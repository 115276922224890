import React from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'

export default function BranchSpecWizardForm({ form, setForm, branch }) {
	return (
		<React.Fragment>
			<Row className='mt-15'>
				<InputGroup as={Col}>
					<InputGroup.Text>Nombre Propietario:</InputGroup.Text>
					<Form.Control 
						value={form.branch_spec.owner_name}
						onChange={e => setForm({ ...form, branch_spec: { ...form.branch_spec, owner_name: e.target.value } })}
						placeholder="Escriba aquí..."
					/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>NIT Propietario:</InputGroup.Text>
					<Form.Control 
						value={form.branch_spec.owner_document}
						onChange={e => setForm({ ...form, branch_spec: { ...form.branch_spec, owner_document: e.target.value } })}
						placeholder="Escriba aquí..."
					/>
				</InputGroup>
			</Row>
			<Row>
				<InputGroup as={Col}>
					<InputGroup.Text>Nombre Operador:</InputGroup.Text>
					<Form.Control 
						value={form.branch_spec.operator_name}
						onChange={e => setForm({ ...form, branch_spec: { ...form.branch_spec, operator_name: e.target.value } })}
						placeholder="Escriba aquí..."
					/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>NIT Operador:</InputGroup.Text>
					<Form.Control 
						value={form.branch_spec.operator_document}
						onChange={e => setForm({ ...form, branch_spec: { ...form.branch_spec, operator_document: e.target.value } })}
						placeholder="Escriba aquí..."
					/>
				</InputGroup>
				<p><em>Modificar datos del operador en caso de ser diferente al propietario</em></p>
			</Row>
		</React.Fragment>
	)
}