import React from 'react'
import { InputGroup, Form } from 'react-bootstrap'

import { BottomBar } from '../../../../components/WizardComponents'

import HasEdsWizardForm from '../../../BranchSpecs/partials/HasEdsWizardForm'
import HasHighVolumeWizardForm from '../../../BranchSpecs/partials/HasHighVolumeWizardForm'
import HasTanksEdsWizardForm from '../../../BranchSpecs/partials/HasTanksEdsWizardForm'

import { updateBranchSpec } from '../../../BranchSpecs/services'
import { handleError } from '../../../../helpers'

export default function TypeAndTanksStep({ form, setForm, currentStep, setCurrentStep }) {

	const handleBackStep = () => {
		setCurrentStep(currentStep-1)
	}

	const handleNextStep = () => {
		updateBranchSpec(form.branch_spec_id, form.branch_spec)
			.then(() => {
				if(form.business_scope === 'pruebas') setCurrentStep(currentStep+2)
				else setCurrentStep(currentStep+1)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<InputGroup>
				<InputGroup.Text>TIPO DE INSTALACIÓN A INSPECCIONAR:</InputGroup.Text>
				<Form.Control value={ form.instalation_type.name } disabled />
			</InputGroup>
			{ form.instalation_type.has_tanks === 1 && (
				<HasTanksEdsWizardForm form={form} setForm={setForm} />
			)}
			{ form.instalation_type.has_eds === 1 && (
				<HasEdsWizardForm form={form} setForm={setForm} />
			)}
			{ form.instalation_type.has_high_volume === 1 && (
				<HasHighVolumeWizardForm form={form} setForm={setForm} />
			)}
			<BottomBar handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
		</React.Fragment>
	)
}