import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

import ServicePlanDocument from './partials/ServicePlanDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetServicePlan } from './services'
import { handleError } from '../../helpers'

export default function ServicePlanPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [servicePlan, setServicePlan] = useState(null)

	useEffect(() => {
		!servicePlan && isConnected && publicGetServicePlan(id, {
			include: 'serviceInspector.service.proposal.inquiry.branchSpec.branch.company,serviceInspector.user,planTasks,serviceInspector.service.proposal.inquiry.employee,formatVersion'
		})
			.then(res => setServicePlan(res.data.data))
			.catch(error => handleError(error))
	}, [servicePlan, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!servicePlan) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ServicePlanDocument servicePlan={servicePlan} />
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`