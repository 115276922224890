import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import ServiceForm from './ServiceForm'
import { updateService } from '../services'
import { message } from 'antd'
import { handleError } from '../../../helpers'

export default function EditServiceModal({ visible, onClose, service, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			hours_estimate: service.hours_estimate,
			start_at: moment(service.start_at).format('YYYY-MM-DD'),
			observers: service.observers
		}
	})
	
	const onSubmit = values => {
		updateService(service.id, values)
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Servicio</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceForm
							register={register} 
							errors={errors} 
						/>
						<Button color="primary" type="submit">Actualizar Registro</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}