import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'

import PreInvoicesTable from './partials/PreInvoicesTable'
import NewPreInvoiceModal from './partials/NewPreInvoiceModal'

import { getPreInvoices } from './services'
import { handleError } from '../../helpers'

export default function ProposalPreInvoicesModal({ visible, onClose, proposalId, reload }) {
	const [preInvoices, setPreInvoices] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!preInvoices && getPreInvoices({ 
			'filter[proposal_id]': proposalId,
		})
			.then(res => setPreInvoices(res.data.data))
			.catch(error => handleError(error))
	}, [preInvoices, proposalId])

	return (
		<React.Fragment>
			<Modal isOpen={visible} size='lg'>
				<ModalHeader toggle={onClose}>Cuentas de Cobro</ModalHeader>
				<ModalBody>
					{ preInvoices ?  (
						<React.Fragment>
							<PreInvoicesTable 
								preInvoices={preInvoices} 
								reload={() => {
									setPreInvoices(null)
									reload()
								}} 
							/>
							<Button className='float-right mt-10' color='primary' onClick={() => setShowNewModal(true)}>
								Agregar Cuenta de Cobro
							</Button>
						</React.Fragment>
					) : <Spinner />}
				</ModalBody>
			</Modal>
			{ showNewModal === true && (
				<NewPreInvoiceModal 
					visible
					onClose={() => setShowNewModal(false)}
					proposalId={proposalId}
				/>
			)}
		</React.Fragment>
	)
}