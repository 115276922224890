import React from 'react'
import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message, Upload } from "antd"
import { InboxOutlined } from '@ant-design/icons'
import AWS from "aws-sdk"
import moment from 'moment'

import { updateReviewLogbook } from '../services'
import { handleError } from '../../../helpers'

const { Dragger } = Upload

export default function ReviewAttachmentModal({ 
	visible, onClose, reviewLogbookId, reload, attribute
}) {

	const props = {
		multiple: false,
		customRequest({
			file,
			onError,
			onProgress,
			onSuccess,
		}) {
			AWS.config.update({
				accessKeyId: "AKIA4NB262CULJ4WQ2HK",
    			secretAccessKey: "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"
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'dewar' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop();
		  let fileName = "app/review_logbooks/" + reviewLogbookId + "/" + moment().format('hhmmss') + '.' + fileExt
		
			const objParams = {
				Bucket: "dewar",
				Key: fileName,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message);
					} else {
						onSuccess(data.response, file);
						updateReviewLogbook(reviewLogbookId, {
							[attribute]: `https://dewar.s3.us-east-1.amazonaws.com/${fileName}`
						})
							.then(res => {
								message.success(res.data.message)
								reload()
								onClose()
							})
							.catch(error => handleError(error))
					}
				});
			}
		};

	
	const getTitle = () => {
		switch (attribute) {
			case 'results_graphic_uri':
				return 'Adjuntar Gráfico de Resultados'

			case 'results_data_uri':
				return 'Adjuntar Archivo de Datos de Resultados'

			case 'results_scheme_uri':
				return 'Adjuntar Esquema de Distribución de Equipos'

			default:
				return ''
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>{getTitle()}</ModalHeader>
				<ModalBody>
					<Alert color='warning'>Subir un archivo sobreescribira el que tenga actualmente.</Alert>
					<Dragger {...props}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Haga clic o arraste el archivo aquí</p>
						<p className="ant-upload-hint">
							Solo se permite adjuntar un archivo menor a 5MB
						</p>
					</Dragger>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}