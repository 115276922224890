import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function TankForm({ tank, register, errors }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Número de Tanque <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('tank_number', { required: true })}
						placeholder="Escriba aquí..."
						defaultValue={tank && tank.tank_number}
					/>
					{ errors.total_corriente && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Tipo de Tanque</Form.Label>
					<Form.Control
						as="select"
						{...register('type', { required: true })}
						defaultValue={tank && tank.type}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="superficial">Tanque Superficial</option>
						<option value="enterrado">Tanque Enterrado</option>
					</Form.Control>
					{ errors.type && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Galones de Gasolina Corriente <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_corriente', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_corriente : 0}
					/>
					{ errors.total_corriente && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Galones de Gasolina Extra <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_extra', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_extra : 0}
					/>
					{ errors.total_extra && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Galones ACPM/DIESEL/BIODIESEL <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_disel', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_disel : 0}
					/>
					{ errors.total_disel && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Galones de NAFTA <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_nafta', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_nafta : 0}
					/>
					{ errors.total_nafta && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Crudo y Mezclas <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_crudo', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_crudo : 0}
					/>
					{ errors.total_crudo && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Galones de FUEL OIL <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_oil', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_oil : 0}
					/>
					{ errors.total_oil && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Galones de JET A <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_jet', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_jet : 0}
					/>
					{ errors.total_jet && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Galones de AVGAS <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_avgas', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_avgas : 0}
					/>
					{ errors.total_avgas && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Galones de ETANOL <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_etanol', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_etanol : 0}
					/>
					{ errors.total_etanol && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Otro <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('total_aceite', { required:true })}
						placeholder="Escriba aquí..."
						defaultValue={tank ? tank.total_aceite : 0}
					/>
					{ errors.total_aceite && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>¿Cuál? <small>(opcional)</small></Form.Label>
					<Form.Control 
						{...register('other_product', {})}
						placeholder="Nombre del producto"
						defaultValue={tank && tank.other_product}
					/>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label className='mr-10'>Sumar en total de tanques:</Form.Label>
				<Form.Check 
					{...register('should_sum')}
					label="Si"
					value="1"
					type="radio"
					inline
					defaultChecked={tank ? tank.should_sum ? 1 : 0 : 1}
				/>
				<Form.Check 
					{...register('should_sum')}
					label="No"
					value="0"
					type="radio"
					inline
					defaultChecked={tank ? !tank.should_sum ? 1 : 0 : 0}
				/>
			</Form.Group>
		</React.Fragment>
	)
}