import React from 'react'
import QRCode from 'react-qr-code'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'

import logo from '../../../../assets/images/logo_dewar_onac.png'

export default function CERTIFICATE_TEMPLATE_INS01({ data, filters }) {
	const serviceReview = data.service_veredict.service_report.service_review
	const serviceInspector = serviceReview.service_plan.service_inspector
	const branch = serviceInspector.service.proposal.inquiry.branch_spec.branch

	const totalPages = serviceReview.tank_specs.length <= 7 ? 1 : ( serviceReview.tank_specs.length <= 27 ? 2 : (serviceReview.tank_specs.length <= 47 ? 3 : (serviceReview.tank_specs.length <= 67 ? 4 : 5)) )

	return (
		<React.Fragment>
			<Document 
				className='certificateContainer' 
				hideBackground={filters.hideBackground}
				isOfficial={filters.isOfficial}
			>
				<div className='clearfix mb-20' />
				<div className='text-center'>
					<DocumentHeader 
						filters={filters}
						data={data}
						branch={branch}
					/>

					<p className='mb-7'>Descripción de la infraestructura inspeccionada:</p>

					<div>
                        { branch.instalation_type_name.includes('Estación de Servicio') && (
                            <table className='full-width border text-center table-small mb-2'>
                                <tbody>
                                    <tr>
                                        <td colSpan={8} className='bold bg-light'>Cantidades Específicas (Totales)</td>
                                    </tr>
                                    <tr>
                                        <td className='bold border bg-light'>Tanques</td>
                                        <td className='border'>{data.eds_stats.total_tanks}</td>
                                        <td className='bold border bg-light'>Mangueras</td>
                                        <td className='border'>{data.eds_stats.total_mangueras}</td>
                                        <td className='bold border bg-light'>Islas</td>
                                        <td className='border'>
                                            {(data.service_veredict.service_report.service_review.island_specs && data.service_veredict.service_report.service_review.island_specs.length > 0) ? data.service_veredict.service_report.service_review.island_specs[0].island_number : 0}
                                        </td>
                                        <td className='bold border bg-light'>Equipos</td>
                                        <td className='border'>{data.eds_stats.total_equipos}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>

					<div className='main_table'>
						<table className='full-width border text-center table-small'>
							<tbody>
								{ branch.instalation_type_name.includes('Planta') && (
									<tr>
										<th colSpan={2}>Cantidad de Tanques</th>
										<th>{parseInt(serviceReview.total_tanks_underground) + parseInt(serviceReview.total_tanks_ground)}</th>
									</tr>
								)}
								<tr className='bold'>
									<th className='border bg-light'>Tanque</th>
									<th className='border bg-light'>Producto Almacenado</th>
									<th className='border bg-light'>Capacidad (gal)</th>
								</tr>
								{ serviceReview.tank_specs.map((tank, i) => i < 7 && (
									<tr key={i}>
										<td className='border'>
										{tank.tank_number}
										</td>
										<td className='border'>
											{tank.other_product_name ? tank.other_product_name : tank.product_name}
										</td>
										<td className='border'>
											{tank.tank_capacity}
										</td>
									</tr>
								)) }
								{ serviceReview.tank_specs.length <= 7 ? (
									<tr>
										<td colSpan={2} className='border bold text-right pr-5'>Capacidad Total</td>
										<td className='bold'>
											{ data.tanks_total_capacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
										</td>
									</tr>
								) : (
									<tr>
										<td colSpan={3}>Nota: Otros tanques ver anexos</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>

					<div className='details_container text-left mt-2 mb-5'>
						<p className='small'>Observaciones: {data.observations ? data.observations : <em>Sin observaciones</em>}</p>
					</div>

					<DocumentFooter 
						filters={filters} 
						data={data} 
						page={1} 
						pageTotal={totalPages}
					>
						<div className='text-center mb-10'>
							<p style={{ fontSize:'13px' }} className='bold mb-5'>Edición {data.modifications_count ? parseInt(data.modifications_count) + 1 : 1} – Emisión del Certificado. {data.format_version.sku}</p>
							<p className='xs'>La vigencia del certificado es de 2 años a partir de la fecha de emisión, está determinada por el numeral 13.1 de la Resolución 40198 de 2021 o aquella que lo modifique o sustituya. Este certificado está sujeto a lo establecido en el documento DN5 Reglamento de Inspección. El presente certificado demuestra el cumplimiento de la instalación con base en la infraestructura señalada, los cambios realizados después de la fecha de inspección inicial deben ser reportados al Ministerio de Minas y Energía. Para verificar validez del certificado comunicarse Celular 3163702077; organismo@dewar.com.co</p>						
						</div>
					</DocumentFooter>
				</div>
			</Document>
			{ serviceReview.tank_specs.length > 7 && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraTanks 
						filters={filters}
						data={data}
						branch={branch}
						serviceReview={serviceReview}
						page={2}
						pageTotal={totalPages}
					/>
				</Document>
			)}
			{ serviceReview.tank_specs.length > 27 && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraTanks 
						filters={filters}
						data={data}
						branch={branch}
						serviceReview={serviceReview}
						page={3}
						pageTotal={totalPages}
					/>
				</Document>
			)}
			{ serviceReview.tank_specs.length > 47 && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraTanks 
						filters={filters}
						data={data}
						branch={branch}
						serviceReview={serviceReview}
						page={4}
						pageTotal={totalPages}
					/>
				</Document>
			)}
		</React.Fragment>
	)
}

const DocumentExtraTanks = ({ filters, data, branch, serviceReview, page, pageTotal }) => (
	<React.Fragment>
		<div className='clearfix mb-20' />
		<div className='text-center full-width'>
			<DocumentHeader 
				filters={filters}
				data={data}
				branch={branch}
			/>
			<h5 className='mb-10'>ANEXO {page-1}</h5>
			<p className='small mb-7'>Descripción de la infraestructura inspeccionada:</p>
			<table className='full-width border text-center table-small mb-2'>
				<tbody>
					<tr className='bold'>
						<th className='border bg-light'>Tanque</th>
						<th className='border bg-light'>Producto Almacenado</th>
						<th className='border bg-light'>Capacidad (gal)</th>
					</tr>
					{ serviceReview.tank_specs.map((tank, i) => {
						let startIndex = page === 2 ? 7 : (page === 3 ? 27 : (page === 4 ? 47 : 67))
						let endIndex = page === 2 ? 26 : (page === 3 ? 46 : (page === 4 ? 66 : 86))
						if(i >= startIndex && i <= endIndex){
							return (
								<tr key={tank.id}>
									<td className='border'>
									{tank.tank_number}
									</td>
									<td className='border'>
										{tank.other_product_name ? tank.other_product_name : tank.product_name}
									</td>
									<td className='border'>
										{tank.tank_capacity}
									</td>
								</tr>
							)
						} else {
							return null
						}
					}) }
					{ page === pageTotal && (
						<tr>
							<td colSpan={2} className='border bold text-right pr-5'>Capacidad Total</td>
							<td className='bold'>
								{ data.tanks_total_capacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
							</td>
						</tr>
					)}
				</tbody>
			</table>

			<DocumentFooter filters={filters} data={data} page={page} pageTotal={pageTotal} />
		</div>
	</React.Fragment>
)

const DocumentHeader = ({ filters, data, branch }) => (
	<React.Fragment>
		{ filters.isOfficial && !filters.hideBackground && (
			<div className='logoDiv'>
				<img src={logo} alt="Logo Dewar y ONAC" className='logoDewarOnac' />
			</div>
		)}
		<h1>DEWAR SAS</h1>
		<p className='sm-width small mb-7'>En DEWAR SAS contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 21-OIN-008, bajo la norma internacional ISO/IEC 17020:2012</p>
		<h5 className='mb-7'>Como Organismo de Inspección concede a:</h5>
		<h5 className='bold'>{data.company_name.toUpperCase()}</h5>
		<h5 className='mb-7'>NIT. {data.company_document}</h5>
		<h5 className='bold'>{branch.name.toUpperCase()}</h5>
		<p className='mb-7'>{branch.address_line}; {branch.address_city}, {branch.address_state}</p>
		<p className='mb-7'><strong>CÓDIGO SICOM</strong> {data.company_sicom}</p>
		<h5 className='bold'>CERTIFICADO DE INSPECCIÓN</h5>
		<p className='sm-width mb-7'>{branch.instalation_type_name}</p>
		<h5 className='mb-7 bold'>{data.sku_formated}</h5>

		<p className='mb-10'>El cual se otorga en cumplimiento de los requisitos establecidos en la {data.article_numerals}</p>
	</React.Fragment>
)

const DocumentFooter = ({ filters, data, page, pageTotal, children }) => (
	<div className='document-footer'>
		<Row className='text-left mb-10'>
			<Col xs="3">
				{ filters.isOfficial && (
					<React.Fragment>
						<img src="/images/firma_11.jpg" alt="firma" className='img-firma' />
						<p className='bold'>Alejandra Martin</p>
						<p className='bold'>Dirección Técnica</p>
						<p className='bold'>DEWAR – OIN</p>
					</React.Fragment>
				)}
			</Col>
			<Col className='text-center pt-20' xs="3">
				{ filters.isOfficial && (
					<a href={`https://admin.dewar.com.co/#/vista_previa/certificados/${data.id}`} target="_blank" rel="noreferrer">
						<QRCode
							style={{ height: "80px", maxWidth: "80px", width: "80px" }}
							value={`https://admin.dewar.com.co/#/vista_previa/certificados/${data.id}`}
						/>
					</a>
				)}
			</Col>
			<Col xs="6" className='text-right pt-20'>
				<p style={{ fontSize: '15px', marginBottom: '5px' }}>
					<strong>FECHA DE EMISIÓN:</strong> {moment(data.issued_at).format('DD-MMM-YYYY')}
				</p>
				<p><strong>Fecha de inspección inicial:</strong> {moment(data.initial_inspection_at).format('DD-MMM-YYYY')}</p>
				{ data.retry_inspection_at && <p><strong>Fecha de inspección cierre:</strong> {moment(data.retry_inspection_at).format('DD-MMM-YYYY')}</p> }
				{ data.modified_at && <p><strong>Fecha de modificación:</strong> {moment(data.modified_at).format('DD-MMM-YYYY')}</p> }
			</Col>
		</Row>
		
		{ children }

		<div className='mb-5 mt-5'>
			<p className='xs'>Página {page}/{pageTotal}</p>
		</div>
		<div className='text-center'>
			<p className='mb-0 small bold'>Carrera 61 # 96 A 23 Oficina 201 Barrio Andes, Bogotá – Colombia. Cel. 3163702077. <br />E-mail. organismo@dewar.com.co</p>
		</div>
	</div>
)

const Document = styled.div`
	width: 216mm;
	min-height: 279mm;
	padding: 0.75in 0.75in 0 0.75in;
	background-image: ${props => !props.hideBackground ? ( props.isOfficial ? `url('/images/fondo_de_agua.jpg')` : `url('/images/fondo_de_agua_borrador.jpeg')` ) : ''};
	background-size: cover;
	background-repeat-x: no-repeat;
	background-color: #fff;
	display: flex;
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 21px;
		margin-bottom: 0px;
		font-weight: bold
	}
	h5 {
		font-size: 16px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 13px;
		height: 15px;
	}
	.details_container {
		padding: 5px;
		border: 1px solid #888;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 7px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	td.border {
		padding: 0px;
  	}
	svg {
		vertical-align: unset;
	}
	.additional_tanks {
		height: 205mm;
		margin: 35px 0px;
	}
	.bg-light {
		background-color: #f2f2f2;
		font-weight: bold;
	}
	.img-firma {
		width: 90px;
		height: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.main_table {
		min-height: 220px;
  	}
  	.logoDewarOnac {
		width: 7.7cm;
  	}
  	.logoDiv {
		width: 7.9cm;
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -50px;
		left: 420px;
		margin-bottom: -50px;
  	}
`