import React from 'react'

import { SectionContent as Content, SectionTitle as Title } from '../../../../components/WizardComponents'

import CompanyDetails from '../../../Companies/partials/CompanyDetails'
import BranchDetails from '../../../Branches/partials/BranchDetails'
import BranchSpecDetails from '../../../BranchSpecs/partials/BranchSpecDetails'
import EmployeeDetails from '../../../Employees/partials/EmployeeDetails'
import HasTanksDetails from '../../../BranchSpecs/partials/HasTanksDetails'
import HasEdsDetails from '../../../BranchSpecs/partials/HasEdsDetails'
import HasHighVolumeDetails from '../../../BranchSpecs/partials/HasHighVolumeDetails'
import PastCertificationDetails from '../PastCertificationDetails'
import InquiryFinalDetails from '../InquiryFinalDetails'

import DocumentHeader from '../../../../components/DocumentHeader'

export default function INQUIRY_TEMPLATE_INS01({ inquiry }) {
	return (
		<React.Fragment>
			<DocumentHeader 
				title="SOLICITUD DE SERVICIO"
				version={inquiry.format_version.sku}
				expireAt={inquiry.format_version.expire_at}
				elaboratedAt={inquiry.created_at}
			/>
			<p>En DEWAR SAS contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 21-OIN-008 bajo la norma ISO/IEC 17020:2012.</p>
			<Title>VERACIDAD Y SUFICIENCIA DE LA INFORMACIÓN</Title>
			<Content>Esta solicitud debe diligenciarse con información veraz, declarando la totalidad de la información que se solicita, para analizar la competencia y alcance de DEWAR. Los datos suministrados en este formato serán verificados durante el proceso y corresponderán a los datos consignados en los documentos resultantes del mismo.</Content>
			<Title>DECLARACIÓN DE IMPARCIALIDAD E INDEPENDENCIA</Title>
			<Content>DEWAR realiza el proceso de inspección de manera imparcial e independiente de entidades relacionadas dedicadas a la propiedad, diseño, construcción / fabricación, suministro, instalación o mantenimiento de las instalaciones objeto de inspección. Las decisiones que se tomen en los procesos de inspección corresponden específicamente a los criterios del Reglamento Técnico objeto de evaluación.</Content>
			<Title>DECLARACIÓN DE CONFIDENCIALIDAD</Title>
			<Content>La información aquí consignada será considerada por el organismo de inspección DEWAR de CARÁCTER CONFIDENCIAL y se garantiza su uso interno aplicado exclusivamente para la elaboración de la propuesta comercial, documentación de la inspección y elaboración de informes y certificados, preparación del servicio y la prestación de este por parte de DEWAR.</Content>
			<Title>1. INFORMACIÓN DE LA EMPRESA QUE SOLICITA EL SERVICIO: *Razón social y NIT de quien sería titular del certificado</Title>
			<Content>
				<CompanyDetails company={inquiry.branch_spec.branch.company} />
			</Content>
			<Title>2. INSTALACIÓN A INSPECCIONAR:</Title>
			<Content>
				<BranchDetails branch={inquiry.branch_spec.branch} />
				<BranchSpecDetails branchSpec={inquiry.branch_spec} />
			</Content>
			<Title>3. NOMBRE DE LA PERSONA RESPONSABLE DE LA INSPECCIÓN EN LA INSTALACIÓN:</Title>
			<Content>
				<EmployeeDetails employee={inquiry.employee} />
			</Content>
			<Title>4. INFORMACIÓN CERTIFICACIÓN:</Title>
			<Content>
				<PastCertificationDetails inquiry={inquiry} />
			</Content>
			<Title>5. TIPO DE INSTALACIÓN A INSPECCIONAR:</Title>
			<Content>
				{/* {inquiry.branch_spec.branch.instalation_type.name} */}
			</Content>
			<Title>6. INFORMACIÓN PARA INSTALACIONES DE COMBUSTIBLE LÍQUIDO:</Title>
			<Content>
				<HasTanksDetails branchSpec={inquiry.branch_spec} />
			</Content>
			<Title>6.1. Información para EDS o Grandes consumidores con EDS privada:</Title>
			<Content>
				<HasEdsDetails branchSpec={inquiry.branch_spec} />
			</Content>
			<Title>6.2. Información para Plantas de almacenamiento o Mayoristas o Grandes consumidores:</Title>
			<Content>
				<HasHighVolumeDetails branchSpec={inquiry.branch_spec} />
			</Content>
			<InquiryFinalDetails inquiry={inquiry} />
		</React.Fragment>
	)
}