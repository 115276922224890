import React from 'react'

import VERIFICATION_LIST_TEMPLATE_INS01 from './templates/VERIFICATION_LIST_TEMPLATE_INS01'

import DocumentTemplate404 from '../../../components/DocumentTemplate404'

export default function VerificationListDocument({ verificationList }) {

	switch (verificationList.format_version.format_template) {
		case 'VERIFICATION_LIST_TEMPLATE_INS01':
			return <VERIFICATION_LIST_TEMPLATE_INS01 data={verificationList} />
	
		default:
			return <DocumentTemplate404 />
	}

}
