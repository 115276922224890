import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { updateServiceReport } from '../../services'
import { handleError } from '../../../../helpers'

export default function ReportResultsDetailsModal({ visible, onClose, serviceReport, reload }) {
    const [loading, setLoading] = useState(false)
	const { register, handleSubmit } = useForm({
        defaultValues: {
            report_details: serviceReport.report_details
        }
    })

	const onSubmit = values => {
        setLoading(true)
		updateServiceReport(serviceReport.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Resultados y Observaciones</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
                            <Form.Label>RESULTADOS PRUEBA HERMETICIDAD TUBERÍAS</Form.Label>
                            <Form.Control 
                                {...register('results_details.prueba_hermeticidad', {})}
                                placeholder='Escribe aquí...'
                                as="textarea"
                                style={{ height: '70px' }}
                                defaultValue='La prueba de hermeticidad de las tuberías de entrada (llenado), desfogue  y de entrega de combustible que van desde la bomba sumergible o del tanque de combustible hasta los equipos dispensadores /surtidores se adelantó inyectando gas inerte a la tubería, hasta alcanzar una presión final de prueba cercana a 50 PSI (El equivalente al 110% de la presión de trabajo de la bomba), y no inferior a 42,7 PSI, condiciones de operación normal del equipo, verificando que ésta se mantiene sin variación alguna durante no menos una (1) hora.'
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>RESULTADOS CONFORME</Form.Label>
                            <Form.Control 
                                {...register('results_details.resultados_conforme', {})}
                                placeholder='Escribe aquí...'
                                as="textarea"
                                style={{ height: '70px' }}
                                defaultValue='Los tanques conformes al momento de la prueba:  -  No tienen fisuras o perforaciones en su estructura. - No tienen fugas de combustibles en las conexiones, tuberías y/o accesorios. - No tienen entradas de agua por las paredes del tanque. - Se mantuvo la presión. Las tuberías conformes al momento de la prueba incluyen: - Las líneas de desfogue de los tanques funcionan correctamente y no presentan pérdida de presión. - Las tuberías de abastecimiento de combustible de los tanques a los equipos dispensadores mantienen la presión. - Las válvulas de impacto de los equipos funcionan correctamente.'
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>RESULTADOS NO CONFORME</Form.Label>
                            <Form.Control 
                                {...register('results_details.resultados_no_conforme', {})}
                                placeholder='Escribe aquí...'
                                as="textarea"
                                style={{ height: '70px' }}
                                defaultValue='Si alguno de los items presenta resultado no conforme, El Cliente  tendrá que realizar los correctivos necesarios y solicitar un proceso de inspección para evaluar la conformidad del tanque o tubería. El tanque o la tubería no conforme no se incluirá dentro de la Declaración de la conformidad.'
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>OBSERVACIONES ADICIONALES</Form.Label>
                            <Form.Control 
                                {...register('results_details.observaciones_adicionales', {})}
                                placeholder='Escribe aquí...'
                                as="textarea"
                                style={{ height: '70px' }}
                                defaultValue='Antes y después de la prueba se verificarón los niveles en los pozos de observación y/o monitores para el caso de tanques enterrados así como el espacio anular en los tanques de doble contención, registrando las observaciones, nivel freático y presencia o no de contaminación tal como se muestra en la bitácora de servicio anexa.'
                            />
                        </Form.Group>
						<Button color="primary" type="submit" disabled={loading}>
                            Actualizar Resultado Final
                        </Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}