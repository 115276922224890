import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ProposalsList from './ProposalsList'
import ProposalWizard from './ProposalWizard'
import ProposalPreview from './ProposalPreview'
import AcceptancePreview from './AcceptancePreview'

export default function Proposals() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/proposals" component={ProposalsList} />
				<Route exact path="/proposals/wizard/:inquiryId" component={ProposalWizard} />
				<Route exact path="/proposals/recertification/wizard/:inquiryId/:certificateId" component={ProposalWizard} />
				<Route exact path="/proposals/preview/:id" component={ProposalPreview} />
				<Route exact path="/proposals/acceptance_preview/:id" component={AcceptancePreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}