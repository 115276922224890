import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message, Switch } from 'antd'
import moment from 'moment'

import { updateCertificate } from '../services'
import { handleError } from '../../../helpers'

export default function EditCertificateModal({ visible, onClose, certificate, reload }) {
	const [countModification, setCountModification] = useState(true)
	const { register, handleSubmit, formState: { errors } } = useForm()

	const onSubmit = values => {
		let modifications = countModification ? {
			modifications_count: parseInt(certificate.modifications_count) + 1,
			modified_at: moment().format('YYYY-MM-DD'),
		} : {}

		if(certificate.sku.toString() === values.sku) delete values.sku

		updateCertificate(certificate.id, { ...modifications, ...values })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Certificado de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Razón Social <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('company_name', { required: true })}
								defaultValue={certificate.company_name}
							/>
							{ errors.company_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Fecha Expedición <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('issued_at', { required: true })}
								type="date"
								defaultValue={moment(certificate.issued_at).format('YYYY-MM-DD')}
							/>
							{ errors.issued_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>NIT <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									{...register('company_document', { required: true })}
									defaultValue={certificate.company_document}
								/>
								{ errors.company_document && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							{ certificate.format_version.business_scope === 'inspeccion' && (
								<Form.Group as={Col}>
									<Form.Label>Código SICOM <span className='text-danger'>*</span></Form.Label>
									<Form.Control 
										{...register('company_sicom', { required: true })}
										defaultValue={certificate.company_sicom}
									/>
									{ errors.company_sicom && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
								</Form.Group>
							)}
						</Row>
						<Form.Group>
							<Form.Label>Consecutivo <span className='text-danger'>*</span></Form.Label>
							<InputGroup>
								<InputGroup.Text>CERT-</InputGroup.Text>
								<Form.Control 
									type="number"
									defaultValue={certificate.sku}
									{...register('sku', { required: true })}
								/>
								<InputGroup.Text>-YYYY</InputGroup.Text>
							</InputGroup>
							{ errors.sku && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Observaciones <small>(opcional)</small></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('observations')}
								style={{ height: '70px' }}
								defaultValue={certificate.observations}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Numerales <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								as="textarea"
								style={{ height: '70px' }}
								{...register('article_numerals', { required: true })}
								defaultValue={certificate.article_numerals}
							/>
							{ errors.article_numerals && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>URL Adjunto <small>(opcional)</small></Form.Label>
							<Form.Control 
								type="url"
								{...register('attachment_url')}
								defaultValue={certificate.attachment_url}
							/>
						</Form.Group>
						<Form.Group className='mt-10 mb-10'>
							<Switch 
								checked={countModification} 
								onChange={() => setCountModification(!countModification)} 
							/>
							<span className='ml-5'>Contar como módificación</span>
						</Form.Group>
						<Button color="primary" type="submit">Modificar Certificado</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}