import React, { useState, useEffect } from 'react'
import { Descriptions, Switch } from 'antd'
import moment from 'moment'

import VerificationListTasksTable from '../VerificationLists/partials/VerificationListTasksTable'
import PendingTasksListModalOffline from './partials/PendingTaskModalOffline'
import InternetRequired from './partials/InternetRequired'

const { Item } = Descriptions

export default function VerificationListTabOffline({ service, filters, setFilters }) {
	const [showTasksModal, setShowTasksModal] = useState(false)
	const [verificationTasks, setVerificationTasks] = useState(null)

	const verificationList =  service.verification_list

	useEffect(() => {
		!verificationTasks && (
			setVerificationTasks(service.verification_list.verification_tasks.filter(task => (
				filters.onlyUncompliant ? (
					(task.is_compliant === 0 && task.is_active === 1) || task.observations_retry
				) : true 
			)))
		)
	}, [ verificationTasks, verificationList, filters, service ])

	return (
		<React.Fragment>
			<h6 className='mt-10 mb-0 text-center'>Lista de Verificación</h6>
			{ verificationList ? (
				<React.Fragment>
					<Descriptions bordered size="small" className='mt-15' column={2}>
						<Item label="Versión">{verificationList.format_version.sku}</Item>
						<Item label="Fecha Vigencia">{moment(verificationList.format_version.expire_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
					</Descriptions>
					<p className='bold mt-15'>TAREAS REGISTRADAS EN LISTA</p>
					<div className='mb-10 text-right'>
						<Switch 
							checked={filters['onlyUncompliant']} 
							onChange={() => {
								setFilters({ ...filters, onlyUncompliant: !filters['onlyUncompliant'] })
								setVerificationTasks(null)
							}} 
						/>
						{" "}Ver solo no conformidades
					</div>
					<VerificationListTasksTable
						editable
						verificationList={verificationList}
						verificationTasks={verificationTasks}
						reloadTasks={() => setVerificationTasks(null)}
						updateTasks={() => setVerificationTasks(null)}
						isRetry={service.initial_service_id ? true : false}
						serviceId={service.id}
					/>
					{ !service.initial_service && (
						<div className='text-right mt-15 mb-10'>
							<p className='text-link inline' onClick={() => setShowTasksModal(true)}>
								Ver Tareas Pendientes
							</p>
						</div>
					)}
				</React.Fragment>
			) : (
				<InternetRequired />
			)}
			{ showTasksModal && (
				<PendingTasksListModalOffline
					visible
					onClose={() => setShowTasksModal(false)}
					verificationList={verificationList}
					instalationTypeId={service.proposal.inquiry.branch_spec.branch.instalation_type_id}
					serviceReviewId={service.service_inspector.service_plan.service_review.id}
					reload={() => setVerificationTasks(null)}
					editable={service.is_editable}
					filters={filters['pendingTasks']}
					setFilters={values => setFilters({ ...filters, pendingTasks: values })}
					serviceId={service.id}
				/>
			)}
		</React.Fragment>
	)
}