import dewarApi from '../../services/dewarApi'

export const getReviewLogbooks = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/review_logbooks', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReviewLogbook = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/review_logbooks/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReviewLogbook = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/review_logbooks', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReviewLogbook = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/review_logbooks/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReviewLogbook = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/review_logbooks/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetReviewLogbook = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/review_logbooks/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)