import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'

export default function ACCEPTANCE_TEMPLATE_PRU01({ data, hideHeader = false }) {
	return (
		<Document>
			{ !hideHeader && (
				<DocumentHeader 
					title="CARTA DE ACEPTACIÓN"
					expireAt={moment()}
				/>
			)}
			<p className='mb-5'>Señor</p>
			<p className='mb-5'><strong>Oscar Alberto Domínguez</strong></p>
			<p className='mb-5'>Representante Legal</p>
			<p className='mb-5'><strong>DEWAR S.A.S</strong></p>
			<p className='mb-20'>Carrera 61 # 96 A 23 Oficina 201 Barrio Andes</p>
			<p className='mb-30'>Bogotá – Colombia</p>
			<p>Yo, <strong>{data.inquiry.branch_spec.branch.company.legal_owner_name}</strong> identificado con la cédula de ciudadanía N° <strong>{data.inquiry.branch_spec.branch.company.legal_owner_document}</strong> como Representante Legal de <strong>{data.inquiry.branch_spec.branch.company.name}</strong> NIT <strong>{data.inquiry.branch_spec.branch.company.complete_document}</strong> solicito que se adelante las pruebas de hermeticidad a tanques y/o tuberías en la instalación <strong>{data.inquiry.branch_spec.branch.name}</strong> de acuerdo a la propuesta N° <strong>{data.sku}</strong> presentada por el Organismo de Evaluación de la Conformidad DEWAR SAS.</p>
			<p>Declaro conocer y acoger el documento DN5 “Reglamento de inspección” establecido por DEWAR, así como el alcance del proceso de inspección – pruebas de hermeticidad que se realizaran a nuestras instalaciones.</p>
			
			<p className='mb-5 mt-40'>Atentamente,</p>
			<p className='mb-5'>Nombre: <strong>{data.inquiry.branch_spec.branch.company.legal_owner_name}</strong></p>
			<p className='mb-40'>Cédula: <strong>{data.inquiry.branch_spec.branch.company.legal_owner_document}</strong></p>

			<p className='pt-20'>______________________________</p>
			<p>Firma Representante Legal</p>
		</Document>
	)
}

const Document = styled.div`
	max-width: 1250px;
	padding: 15px 40px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
`