import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import InquiriesTable from './partials/InquiriesTable'
import NewInquiryModal from './partials/NewInquiryModal'
import InquiryTicketsModal from './partials/tickets/InquiryTicketsModal'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getInquiries } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function InquiriesList() {
	const user = useSelector(state => state.auth.user)
	const { isConnected } = useSelector(state => state.offline)
	const [inquiries, setInquiries] = useState(null)
	const [filterType, setFilterType] = useState('branchSpec.branch.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterBusinessScope, setFilterBusinessScope] = useState('')
	const [showNewInquiryModal, setShowNewInquiryModal] = useState(false)
	const [showInquiryTicketsModal, setShowInquiryTicketsModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		let params = { [`filter[${filterType}]`]: filterValue, include: 'branchSpec.branch,proposals' }
		if(user.role.includes('inspector')) params['filter[proposals.services.serviceInspector.user.id]'] = user.id

		!inquiries && isConnected && getInquiries({
			...pagination,
			...params,
			'filter[formatVersion.business_scope]': filterBusinessScope,
			sort: '-created_at',
		})
			.then(res => {
				setInquiries(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [inquiries, filterType, filterValue, user, isConnected, pagination, filterBusinessScope])

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Solicitudes de Servicio"
				subtitle="Plataforma DEWAR"
				reload={() => setInquiries(null)}
				options={[
					{ label: 'Buscar por nombre de instalación', value: 'branchSpec.branch.name' },
					{ label: 'Buscar por nombre de empresa', value: 'branchSpec.branch.company.name' },
					{ label: 'Buscar por tipo de instalación', value: 'branchSpec.branch.instalationType.name' },
					{ label: 'Buscar por número de consecutivo', value: 'sku_key' },
					{ label: 'Buscar por año de consecutivo (YYYY)', value: 'sent_at' },
					{ label: 'Buscar por fecha de solicitud (YYYY-MM-DD)', value: 'sent_at' },
				]}
				ctaText="Agregar Solicitud"
				handleCtaClick={() => setShowNewInquiryModal(true)}
				secundaryCtaText="Ver Pre-Solicitudes"
				handleSecundaryCtaClick={() => setShowInquiryTicketsModal(true)}
				filterScope={filterBusinessScope}
				setFilterScope={setFilterBusinessScope}
				scopeLabel="Tipo de Servicio"
				scopes={[
					{ label: 'Servicios de Inspección', value: 'inspeccion' },
					{ label: 'Pruebas de Tanques y Tuberías', value: 'pruebas' },
				]}
			/>
			<Card>
				<CardBody>
					<InquiriesTable 
						inquiries={inquiries}
						reload={() => setInquiries(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewInquiryModal && (
				<NewInquiryModal 
					visible
					onClose={() => setShowNewInquiryModal(false)}
					reload={() => setInquiries(null)}
				/>
			)}
			{ showInquiryTicketsModal && (
				<InquiryTicketsModal 
					visible
					onClose={() => setShowInquiryTicketsModal(false)}
					reload={() => setInquiries(null)}
				/>
			)}
		</React.Fragment>
	)
}