import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ServiceReportPreview from './ServiceReportPreview'
import ServiceReportProfile from './ServiceReportProfile'
import ServiceReportsList from './ServiceReportsList'

export default function ServiceReports() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/service_reports" component={ServiceReportsList} />
				<Route exact path="/service_reports/:id" component={ServiceReportProfile} />
				<Route exact path="/service_reports/preview/:id" component={ServiceReportPreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}