import dewarApi, { getDomain } from '../../services/dewarApi'

export const getServiceReports = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/service_reports', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceReport = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/service_reports/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetServiceReport = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/service_reports/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceReport = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/service_reports', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceReport = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/service_reports/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceReport = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/service_reports/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceReports = async (name, token) => (
	fetch( getDomain()+`/service_reports?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_report => ({
				label: service_report.name,
				value: service_report.id,
			})),
		)
)

export const notifyServiceReport = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/service_reports/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)