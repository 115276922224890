import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'

export default function ReportProfileTopBar({ serviceReport }) {
	const user = useSelector(state => state.auth.user)

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className='float-right pt-10'>
						{ (user.role === 'admin' || user.role.includes('inspector')) && (
							<Link to={`/services/${serviceReport.service_review.service_plan.service_inspector.service.id}`}>
								<Button color='primary' outline className='ml-10'>
									Ver Servicio de Inspección
								</Button>
							</Link>
						)}
					</div>
					<CardTitle>Informe de Inspección {serviceReport.sku_formated}</CardTitle>
					<CardSubtitle>
						Propuesta {serviceReport.service_review.service_plan.service_inspector.service.proposal.sku}{" | "}
						Servicio de Inspección #{serviceReport.service_review.service_plan.service_inspector.service.id}
					</CardSubtitle>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}