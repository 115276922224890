import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Divider, Empty, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faImage, faSearch } from '@fortawesome/free-solid-svg-icons'

import EditReviewLogbookModal from './partials/EditReviewLogbookModal'
import ReviewAttachmentModal from './partials/ReviewAttachmentModal'
import NewServiceVeredictModal from '../ServiceVeredicts/partials/NewServiceVeredictModal'

import { getReviewLogbooks } from './services'
import { handleError } from '../../helpers'

export default function ReviewLogbookVeredictsTab({ serviceReport, reload }) {
    const [showNewVeredictModal, setShowNewVeredictModal] = useState(null)
    const [editModal, setEditModal] = useState(null)
    const [reviewLogbooks, setReviewLogbooks] = useState(null)
    const [resultsGraphicModal, setResultsGraphicModal] = useState(null)
    const [resultsDataModal, setResultsDataModal] = useState(null)
    const history = useHistory()

    useEffect(() => {
        !reviewLogbooks && getReviewLogbooks({
            'filter[service_review_id]': serviceReport.service_review.id
        })
            .then(res => setReviewLogbooks(res.data.data))
            .catch(error => handleError(error))
    }, [ reviewLogbooks, serviceReport ])

    if(!serviceReport.service_veredict){
        return (
            <React.Fragment>
                <Empty description="No se ha iniciado la revisión y decisión aún" className='pt-50'>
                    <Button color="primary" onClick={() => setShowNewVeredictModal(true)}>
                        Iniciar Revisión y Decisión
                    </Button>
                </Empty>
                { showNewVeredictModal && (
                    <NewServiceVeredictModal
                        visible
                        onClose={() => setShowNewVeredictModal(false)}
                        serviceReport={serviceReport}
                        reload={reload}
                    />
                )}
            </React.Fragment>
        )
    }

    if(!reviewLogbooks) 
        return <Spinner animation='border' size="sm" />

    const columns = [
        {
            title: 'Consecutivo',
            dataIndex: 'id',
            width: '100px',
            render: t => `BIT00${t}`
        },
        {
            title: 'Tipo',
            dataIndex: 'spec_type',
            render: t => t === 'tank' ? 'Tanque' : 'Tubería'
        },
        {
            title: 'Número',
            dataIndex: 'spec_number',
        },
        {
            title: 'Producto',
            dataIndex: 'product_name',
            render: (t, r) => t === 'Otro' ? `${t} - ${r.product_other_name}` : t
        },
        {
            title: 'Decisión Director Técnico',
            dataIndex: 'requirements_survey',
            render: (t, r) => (
                <React.Fragment>
                    {t.is_director_compliant ? t.is_director_compliant : <em className='text-muted'>Sin registro</em>}
                    <Divider type='vertical' />
                    <span className='text-link small' onClick={() => setEditModal({ 
                        key: 'directorVeredict',  
                        reviewLogbook: r
                    })}>
                        {t.is_director_compliant ? 'Actualizar' : 'Registrar'} Decisión
                    </span>
                </React.Fragment>
            )
        },
        {
            title: 'Archivo de Datos',
            dataIndex: 'results_data_uri',
            render: (t, r) => t ? (
                <a href={t} target='_blank' rel="noreferrer">Ver Archivo Adjunto</a>
            ) : (
                <em className='small text-muted'>Sin archivo adjunto</em>
            )
        },
        {
            title: 'Equema de Distribución',
            dataIndex: 'results_scheme_uri',
            render: (t, r) => t ? (
                <a href={t} target='_blank' rel="noreferrer">Ver Archivo Adjunto</a>
            ) : (
                <em className='small text-muted'>Sin archivo adjunto</em>
            )
        },
        {
            title: 'Gráfico de Datos',
            dataIndex: 'results_graphic_uri',
            render: (t, r) => t ? (
                <a href={t} target='_blank' rel="noreferrer">Abrir Gráfico Adjunto</a>
            ) : (
                <em className='small text-muted'>Sin gráfico adjunto</em>
            )
        },
        {
            title: 'Acciones',
            render: (t, r) => (
                <React.Fragment>
                    <Tooltip 
                        title="Subir Datos de Resultados"
                        className='text-link'
                        onClick={() => setResultsDataModal(r.id)}
                    >
                        <FontAwesomeIcon icon={faFileExcel} />
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip 
                        title="Subir Gráfico de Resultados"
                        className='text-link'
                        onClick={() => setResultsGraphicModal(r.id)}
                    >
                        <FontAwesomeIcon icon={faImage} />
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip
                        title="Ver Formato de Bitacora" 
                        className='text-link'
                        onClick={() => history.push(`/review_logbooks/preview/${r.id}`)}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                    </Tooltip>
                </React.Fragment>
            )
        }
    ]

    return (
        <React.Fragment>
            <h6 className='mt-10 mb-15 text-center'>Revisión y Decisión de Bitacoras de Inspección</h6>
            <Table 
                dataSource={reviewLogbooks}
                rowKey={r => r.id}
                loading={!reviewLogbooks}
                columns={columns}
                locale={{ emptyText: <Empty description="No se tiene bitacoras asociadas" imageStyle={{ height: '50px' }} /> }}
                size="small"
            />
            { editModal && (
                <EditReviewLogbookModal
                    visible
                    onClose={() => setEditModal(false)}
                    reviewLogbook={editModal.reviewLogbook}
                    reload={() => setReviewLogbooks(null)}
                    formKey={editModal.key}
                />
            )}
            { resultsGraphicModal && (
                <ReviewAttachmentModal
                    visible
                    onClose={() => setResultsGraphicModal(false)}
                    reviewLogbookId={resultsGraphicModal}
                    reload={() => setReviewLogbooks(null)}
                    attribute="results_graphic_uri"
                    title="Adjuntar Gráfico de Resultados"
                />
            )}
            { resultsDataModal && (
                <ReviewAttachmentModal
                    visible
                    onClose={() => setResultsDataModal(false)}
                    reviewLogbookId={resultsDataModal}
                    reload={() => setReviewLogbooks(null)}
                    attribute="results_data_uri"
                    title="Adjuntar Archivo de Datos de Resultados"
                />
            )}
        </React.Fragment>
    )
}