import React from 'react'
import { Empty } from 'antd'

import PreInvoicePreview from '../PreInvoices/PreInvoicePreview'
import ProposalPreview from '../Proposals/ProposalPreview'
import ServicePlanPreview from '../ServicePlans/ServicePlanPreview'
import ServiceReportPreview from '../ServiceReports/ServiceReportPreview'
import ServiceReviewPreview from '../ServiceReviews/ServiceReviewPreview'
import ServiceNotificationPreview from '../ServiceNotifications/ServiceNotificationPreview'
import ServiceInspectorPreview from '../ServiceInspectors/ServiceInspectorPreview'
import RetryNoticePreview from '../RetryNotices/RetryNoticePreview'
import CertificatePreview from '../Certificates/CertificatePreview'
import NonCompliantReportPreview from '../NonCompliantReports/NonCompliantReportPreview'
import DecisionNoticePreview from '../DecisionNotices/DecisionNoticePreview'
import InquiryPreview from '../Inquiries/InquiryPreview'
import AcceptancePreview from '../Proposals/AcceptancePreview'

export default function PreviewFormat({ type, id }) {	
	switch (type) {
		case 'solicitudes':
			return <InquiryPreview id={id} />

		case 'cuentas_de_cobro':
			return <PreInvoicePreview id={id} />
		
		case 'propuestas':
			return <ProposalPreview id={id} />
		
		case 'aceptaciones':
			return <AcceptancePreview id={id} />

		case 'planes':
			return <ServicePlanPreview id={id} />

		case 'informes':
			return <ServiceReportPreview id={id} />

		case 'actas':
			return <ServiceReviewPreview id={id} />

		case 'notificaciones':
			return <ServiceNotificationPreview id={id} />

		case 'asignaciones':
			return <ServiceInspectorPreview id={id} />

		case 'comunicado_insp_cierre':
			return <RetryNoticePreview id={id} />

		case 'certificados':
			return <CertificatePreview id={id} />
		
		case 'certificadosof':
			return <CertificatePreview id={id} isOfficial />

		case 'no_conformidades':
			return <NonCompliantReportPreview id={id} />

		case 'comunicado_decision':
			return <DecisionNoticePreview id={id} />
	
		default:
			<Empty description="No se encontro un formato válido" />
			break;
	}
}