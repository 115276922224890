import React from 'react'
import { Descriptions } from 'antd'
import { text } from '../../../helpers'

const { Item } = Descriptions

export default function EmployeeDetails({ employee }) {
	return (
		<React.Fragment>
			<Descriptions bordered size="small" className="mt-10 mb-10">
				<Item label="Nombre Completo">
					{employee && employee.name}
				</Item>
				<Item label="Cargo">
					{employee && employee.position}
				</Item>
				<Item label="Correo Electrónico">
					{employee && text(employee.email)}
				</Item>
				<Item label="Teléfono">
					{employee && text(employee.phone)}
				</Item>
				<Item label="Celular">
					{employee && text(employee.mobile)}
				</Item>
				<Item label="Sede de Referencia">
					{employee && text(employee.reference_location)}
				</Item>
			</Descriptions>
		</React.Fragment>
	)
}