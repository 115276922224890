import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import authReducer from '../containers/Authentication/reducer'
import offlineReducer from '../containers/Offline/reducer'
import servicesReducer from '../containers/Services/reducer'
import taskTemplatesReducer from '../containers/TaskTemplates/reducer'

const authPersistConfig = { key: 'auth', storage: storage }
const taskTemplatesPersistConfig = { key: 'task_templates', storage: storage }
const servicesPersistConfig = { key: 'services', storage: storage }
const offlinePersistConfig = { key: 'offline', storage: storage }
 
const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	task_templates: persistReducer(taskTemplatesPersistConfig, taskTemplatesReducer),
	services: persistReducer(servicesPersistConfig, servicesReducer),
	offline: persistReducer(offlinePersistConfig, offlineReducer)
})
 
export default rootReducer