import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function UserPasswordModal({ visible, onClose, user }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		updateUser(user.id, values)
			.then(() => {
				message.success('Contraseña actualizada exitosamente.')
				reset()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Contraseña</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label>Nueva Contraseña <span className="text-danger">*</span></Form.Label>
							<Form.Control {...register("password", {required:true})} placeholder="Mínimo 6 digitos" type="password" />
							{ errors.password && <Form.Text className="text-warning">Ingrese una contraseña de 6 dígitos o más.</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Confirmar Nueva Contraseña <span className="text-danger">*</span></Form.Label>
							<Form.Control {...register("password_confirmation", {required:true})} placeholder="Ingrese nuevamente la contraseña" type="password" />
							{ errors.password_confirmation && <Form.Text className="text-warning">Las contraseñas no coinciden.</Form.Text> }
						</Form.Group>
						<Button type="submit" color="primary">Actualizar Contraseña</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}