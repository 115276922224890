import dewarApi, { getDomain } from '../../services/dewarApi'

export const getInquiries = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/inquiries', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getInquiry = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/inquiries/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPublicInquiry = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/inquiries/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeInquiry = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/inquiries', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateInquiry = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/inquiries/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteInquiry = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/inquiries/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchInquiries = async (name, token) => (
	fetch( getDomain()+`/inquiries?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(inquiry => ({
				label: inquiry.name,
				value: inquiry.id,
			})),
		)
)	

export const importInquiries = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/inquiries/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)