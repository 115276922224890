import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import NonCompliantReportDocument from '../NonCompliantReports/partials/NonCompliantReportDocument'

export default function NonCompliantPreviewOffline(props) {
	const { isConnected } = useSelector(state => state.offline)
	const serviceId = props.serviceId ? props.serviceId : props.match.params.serviceId
	const id = props.id ? props.id : props.match.params.id
	
	const service = useSelector(state => state.services.data.filter(s => s.id === parseInt(serviceId))[0])
	const [verificationTasks, setVerificationTasks] = useState(null)

	const verificationList =  service.verification_list

	useEffect(() => {
		!verificationTasks && (
			setVerificationTasks(service.verification_list.verification_tasks.filter(task => (
				((task.is_compliant === 0 && task.is_active === 1) || task.observations_retry)
			)))
		)
	}, [ verificationTasks, service ])

	if(isConnected) return <Redirect to={`/non_compliant_reports/preview/${id}`} />

	if(!verificationList || !verificationTasks) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<NonCompliantReportDocument 
					data={{
						...verificationList,
						verification_tasks: verificationTasks,
						service,
					}}
				/>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`