import React, { useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { message } from 'antd'

import { BottomBar, SectionTitle, SectionContent } from '../../../../components/WizardComponents'
import FormatVersionSelect from '../../../FormatVersions/FormatVersionSelect'
import { dewar_vars } from '../../../../helpers'

export default function IntroStep({ form, setForm, currentStep, setCurrentStep }) {
	const [businessScope, setBusinessScope] = useState('inspeccion')

	const handleNextStep = () => {
		if( !form.sku_key ) return message.warning('Diligencie Consecutivo')
		if( !form.format_version_id ) return message.warning('Diligencie el Código-Versión')

		setCurrentStep(currentStep+1)
	}

	return (
		<React.Fragment>			
			<Row className='mb-10'>
				<InputGroup as={Col}>
					<InputGroup.Text>TIPO DE SERVICIO</InputGroup.Text>
					<Form.Control
						as="select"
						value={businessScope}
						onChange={e => {
							setBusinessScope(e.target.value)
							setForm({ ...form, business_scope: e.target.value, format_version_sku: '', format_version_id: '', expire_at: '' })
						}}
					>
						<option value="inspeccion">Servicio de Inspección</option>
						<option value="pruebas">Pruebas de Tanques y Tuberías</option>
					</Form.Control>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>CÓDIGO - VERSIÓN</InputGroup.Text>
					<FormatVersionSelect 
						keyword="inquiry"
						value={form.format_version_sku}
						onChange={(id, format_version_sku, expire_at) => setForm({ ...form, format_version_sku, format_version_id: id, expire_at })}
						businessScope={businessScope}
					/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>CONSECUTIVO</InputGroup.Text>
					<InputGroup.Text>{businessScope === 'inspeccion' ? dewar_vars.inquiry_ins_prefix : dewar_vars.inquiry_tes_prefix}-</InputGroup.Text>
					<Form.Control
						type="number"
						value={form.sku_number}
						onChange={e => setForm({ 
							...form, 
							sku_number: parseInt(e.target.value),
							sku_key: businessScope === 'inspeccion' ? `${dewar_vars.inquiry_ins_prefix}-${e.target.value}` : `${dewar_vars.inquiry_tes_prefix}-${e.target.value}`
						})}
					/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>FECHA VIGENCIA</InputGroup.Text>
					<Form.Control 
						value={form.expire_at}
						disabled
					/>
				</InputGroup>
			</Row>
			<SectionTitle>VERACIDAD Y SUFICIENCIA DE LA INFORMACIÓN</SectionTitle>
			<SectionContent>Esta solicitud debe diligenciarse con información veraz, declarando la totalidad de la información que se solicita, para analizar la competencia y alcance de DEWAR. Los datos suministrados en este formato serán verificados durante el proceso y corresponderán a los datos consignados en los documentos resultantes del mismo.</SectionContent>
			{ businessScope === 'inspeccion' && (
				<React.Fragment>
					<SectionTitle>DECLARACIÓN DE IMPARCIALIDAD E INDEPENDENCIA</SectionTitle>
					<SectionContent>DEWAR realiza el proceso de inspección de manera imparcial e independiente de entidades relacionadas dedicadas a la propiedad, diseño, construcción / fabricación, suministro, instalación o mantenimiento de las instalaciones objeto de inspección. Las decisiones que se tomen en los procesos de inspección corresponden específicamente a los criterios del Reglamento Técnico objeto de evaluación.</SectionContent>
					<SectionTitle>DECLARACIÓN DE CONFIDENCIALIDAD</SectionTitle>
					<SectionContent>La información aquí consignada será considerada por el organismo de inspección DEWAR de CARÁCTER CONFIDENCIAL y se garantiza su uso interno aplicado exclusivamente para la elaboración de la propuesta comercial, documentación de la inspección y elaboración de informes y certificados, preparación del servicio y la prestación de este por parte de DEWAR.</SectionContent>
				</React.Fragment>
			)}
			<BottomBar handleNextStep={handleNextStep} />
		</React.Fragment>
	)
}