import dewarApi, { getDomain } from '../../services/dewarApi'

export const getServiceNotifications = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/service_notifications', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceNotification = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/service_notifications/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetServiceNotification = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/service_notifications/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceNotification = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/service_notifications', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceNotification = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/service_notifications/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceNotification = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/service_notifications/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceNotifications = async (name, token) => (
	fetch( getDomain()+`/service_notifications?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_notification => ({
				label: service_notification.name,
				value: service_notification.id,
			})),
		)
)

export const notifyServiceNotification = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/service_notifications/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)