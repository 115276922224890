import React from 'react'

import NON_COMPLIANT_REPORT_TEMPLATE_INS01 from './templates/NON_COMPLIANT_REPORT_TEMPLATE_INS01'

import DocumentTemplate404 from '../../../components/DocumentTemplate404'

export default function NonCompliantReportDocument({ verificationList }) {
	
	switch (verificationList.service.service_inspector.service_plan.service_review.non_compliant_report.format_version.format_template) {
		case 'NON_COMPLIANT_REPORT_TEMPLATE_INS01':
			return <NON_COMPLIANT_REPORT_TEMPLATE_INS01 data={verificationList} />
	
		default:
			return <DocumentTemplate404 />
	}
	
}