import { 
	CHECK_CONNECTION,
   QUEUE_REQUEST,
	UNQUEUE_REQUEST,
} from "./actions"

const INITIAL_STATE = {
   isConnected: navigator.onLine,
	queue: []
}

const offlineReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
		case CHECK_CONNECTION:
			return { ...state, isConnected: navigator.onLine }

      case QUEUE_REQUEST:
         return { ...state, queue: [ 
            ...state.queue, 
            {
               key: Date.now(),
               method: 'PUT',
               ...action.payload 
            }
         ]}

      case UNQUEUE_REQUEST:
         return {
            ...state,
            queue: [ ...state.queue.filter(r => r.key !== parseInt(action.payload)) ]
         }
         
      default:
         return { ...state }
   }
}

export default offlineReducer