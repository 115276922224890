import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { Card, CardBody, CardHeader, CardTitle, CardSubtitle, Spinner } from 'reactstrap'
import { message } from 'antd'

import ProposalForm from './partials/ProposalForm'
import ProposalFormPreview from './partials/ProposalFormPreview'
import InternetRequired from '../Offline/partials/InternetRequired'

import { storeProposal } from './services'
import { handleError } from '../../helpers'
import { getInquiry } from '../Inquiries/services'

export default function ProposalWizard(props) {
	const { isConnected } = useSelector(state => state.offline)
	const history = useHistory()
	const inquiryId = props.match.params.inquiryId
	const certificateId = props.match.params.certificateId
	const [inquiry, setInquiry] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [form, setForm] = useState({
		sku_key: '',
		version: '',
		expire_at: '',
		elaborated_at: '',
		service_value: 0,
		quantity: 1,
		trip_expenses_value: 0,
		price_terms: `- El precio de gastos de viaje puede variar, en el caso que por conveniencia del cliente asume directamente el costo de tiquetes aéreos. \n\n- En caso de que el cliente asuma el costo de tiquetes se realizará la variación en el total del servicio en la correspondiente cuenta de cobro/factura. \n\n- Los costos del tiquete están sujetos a variaciones de acuerdo con la programación que se realice. \n\n- El precio total por cancelar es el Total del servicio de inspección más IVA`,
		is_ds_construction: 0
	})

	useEffect(() => {
		!inquiry && isConnected && getInquiry(inquiryId, {
			include: 'branchSpec.branch.instalationType,employee'
		})
			.then(res => {
				setInquiry(res.data.data)
				return res
			})
			.then(res => {
				if(res.data.data.format_version.business_scope === 'pruebas')
					setForm({ 
						...form, 
						quantity: res.data.data.test_details.total_tanks ? res.data.data.test_details.total_tanks : 1,
						price_terms: '-La propuesta incluye las condiciones de seguridad exigidas por la ley y las definidas de acuerdo con nuestro estándar de seguridad, otro requerimiento por parte del Cliente podrán tener un costo adicional, y se debe validar el cumplimiento antes de la programación.  \n\n- Una vez aceptada la propuesta, se confirmará la programación de acuerdo con la disponibilidad del equipo en la zona. \n\n- Los gastos de transporte pueden disminuir si se realiza el servicio con programación en la zona de varios servicios. \n\n- El valor de gastos de transporte puede variar de acuerdo con los tiempos establecidos para el desplazamiento y el lugar donde se encuentre la instalación. \n\n- Los gastos de transporte están sujetos a variaciones de acuerdo con la programación que se realice. \n\n- El valor de la inspección está establecido bajo el cumplimiento del Plan de Inspección del proceso, en caso de presentarse inconvenientes o retrasos por parte del Cliente, se generarán cargos adicionales por concepto del tiempo del Inspector. \n\n- Esta propuesta está sujeta a la programación acordada. \n\n- Los precios están dados para la realización de las actividades en días continuos. \n\n- El precio Total por cancelar es el Total del servicio de inspección más IVA.' 
					})
			})
			.catch(error => handleError(error))
	}, [inquiryId, inquiry, isConnected, form])

	if(!isConnected) return <InternetRequired />

	if(!inquiry) return <Spinner />

	const handleSubmit = () => {
		if(!form.sku_key) return setErrors({ sku_key: true })
		if(!form.format_version_id) return setErrors({ format_version_id: true })
		if(!form.elaborated_at) return setErrors({ elaborated_at: true })
		if(!form.service_value) return setErrors({ service_value: true })
		if(!form.price_terms) return setErrors({ price_terms: true })

		setLoading(true)
		
		storeProposal({ ...form, inquiry_id: inquiryId, previous_certificate_id: certificateId })
			.then(res => {
				message.success(res.data.message)
				history.push(`/proposals/preview/${res.data.data.id}`)
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Row>
				<Col md="12" lg="4">
					<Card>
						<CardHeader>
							<CardTitle>Propuesta {!certificateId ? 'Comercial' : 'Recertificación'}</CardTitle>
							<CardSubtitle>Para solicitud de servicio {inquiry.sku}</CardSubtitle>
						</CardHeader>
						<CardBody>
							<ProposalForm 
								form={form}
								setForm={setForm}
								errors={errors}
								handleSubmit={handleSubmit}
								loading={loading}
								businessScope={inquiry.format_version.business_scope}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col md="12" lg="8">
					<Card>
						<CardBody>
							<ProposalFormPreview 
								form={form}
								inquiry={inquiry}
								isRecertification={certificateId}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}