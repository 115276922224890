import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import { handleError } from '../../helpers'
import { getFormatVersions } from './services'

export default function FormatVersionSelect({ keyword, setValue, value, onChange, defaultValue, businessScope }) {
	const { isConnected } = useSelector(state => state.offline)
	const [formatVersions, setFormatVersions] = useState(null)
	const [selectedVersion, setSelectedVersion] = useState('')

	useEffect(() => {
		!formatVersions && isConnected && getFormatVersions({ 
			'filter[active]': 1,
			'filter[keyword]': keyword,
			'filter[business_scope]': businessScope
		})
			.then(res => setFormatVersions(res.data.data))
			.catch(error => handleError(error))
	}, [ formatVersions, isConnected, keyword, businessScope ])

	useEffect(() => {
		setFormatVersions(null)
	}, [ businessScope ])

	if(!isConnected) return <em>Se requiere de internet</em>

	if(!formatVersions) return <Form.Control disabled value="Cargando..." />

	return (
		<React.Fragment>
			{ setValue ? (
				<Form.Control
					as="select"
					value={selectedVersion}
					onChange={e => {
						let selected = formatVersions.filter(v => v.id === parseInt(e.target.value))[0]
						if(selected){
							setValue('format_version_id', selected.id)
							setValue('expire_at', moment(selected.expire_at).format('YYYY-MM-DD'))
							setValue('format_version_keyword', selected.keyword)
							setSelectedVersion(selected.id)
						}
					}}
					defaultValue={defaultValue}
				>
					<option value="">:: Seleccione una opción ::</option>
					{ formatVersions.map( version => (
						<option key={version.id} value={version.id}>{version.sku}</option>
					)) }
				</Form.Control>
			) : (
				<Form.Control
					as="select"
					value={value}
					onChange={e => {
						let selected = formatVersions.filter(v => v.sku === e.target.value)[0]
						if(selected) onChange(selected.id, selected.sku, moment(selected.expire_at).format('YYYY-MM-DD'), selected.format_template)
					}}
					defaultValue={defaultValue}
				>
					<option value="">:: Seleccione una opción ::</option>
					{ formatVersions.map( version => (
						<option key={version.id} value={version.sku}>{version.sku}</option>
					)) }
				</Form.Control>
			)}
		</React.Fragment>
	)
}