import React from 'react'

import SERVICE_PLAN_TEMPLATE_INS01 from './templates/SERVICE_PLAN_TEMPLATE_INS01';
import SERVICE_PLAN_TEMPLATE_PRU01 from './templates/SERVICE_PLAN_TEMPLATE_PRU01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function ServicePlanDocument({ servicePlan }) {

	switch (servicePlan.format_version.format_template) {
		case 'SERVICE_PLAN_TEMPLATE_INS01':
			return <SERVICE_PLAN_TEMPLATE_INS01 data={servicePlan} />

		case 'SERVICE_PLAN_TEMPLATE_PRU01':
			return <SERVICE_PLAN_TEMPLATE_PRU01 data={servicePlan} />
	
		default:
			return <DocumentTemplate404 />
	}

}