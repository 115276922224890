import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { Descriptions } from 'antd'
import moment from 'moment'

import { text } from '../../../helpers'

const { Item } = Descriptions

export default function ServiceProfileDetails({ service }) {

	const branch = service.proposal.inquiry.branch_spec.branch
	const company = branch.company

	return (
		<Row>
			<Col sm="6" md="4">
				<Card>
					<CardHeader>Detalles Generales</CardHeader>
					<CardBody>
						<Descriptions column={1} size="small" className='labelBold'>
							<Item label="Propuesta Comercial">{service.proposal.sku}</Item>
							<Item label="Fecha">{moment(service.start_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
							{ service.business_scope === 'inspeccion' && (
								<Item label="Tipo de Inspección">{service.type}</Item>
							)}
							<Item label="Horas Estimadas">{service.hours_estimate}</Item>
							<Item label="Criterio">{service.criteria}</Item>
						</Descriptions>
					</CardBody>
				</Card>
			</Col>
			<Col sm="6" md="4">
				<Card>
					<CardHeader>Empresa Solicitante</CardHeader>
					<CardBody>
						<Descriptions column={1} size='small' className='labelBold'>
							<Item label="Razón Social">{company.name}</Item>
							<Item label="NIT">{company.complete_document}</Item>
							<Item label="Emails">{`${text(company.email,' ')} ${text(company.email_secundary,' ')} ${text(company.email_terciary,' ')}`}</Item>
							<Item label="Celular">{text(company.mobile)}</Item>
							<Item label="Teléfono">{text(company.phone)}</Item>
						</Descriptions>
					</CardBody>
				</Card>
			</Col>
			<Col sm="6" md="4">
				<Card>
					<CardHeader>Instalación a Inspeccionar</CardHeader>
					<CardBody>
						<Descriptions column={1} size='small' className='labelBold'>
							<Item label="Sede">{branch.name}</Item>
							<Item label="Bandera">{text(branch.flag)}</Item>
							<Item label="Tipo de Instalación">{branch.instalation_type_name}</Item>
							<Item label="Emails">{`${text(branch.email,' ')} ${text(branch.email_secundary,' ')} ${text(branch.email_terciary,' ')}`}</Item>
							<Item label="Celular">{text(branch.mobile)}</Item>
							<Item label="Teléfono">{text(branch.phone)}</Item>
							<Item label="Dirección">
								{branch.address_line}, {branch.address_state} {branch.address_city}
							</Item>
						</Descriptions>
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
}