import React from 'react'
import styled from 'styled-components'

export default function Error404() {
	return (
		<Container>
			<h4 className="mb-0">Página no encontrada.</h4>
			<p className="mb-0">Revise el link ingresado o dirigase de nuevo al menú</p>
		</Container>
	)
}

const Container = styled.div`
	padding: 20px 20px;
	background-color: #fff;
`