import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Switch } from 'antd'

import ListTopBar from '../../components/ListTopBar'
import EvidenceTemplatesTable from './partials/EvidenceTemplatesTable'
import NewEvidenceTemplateModal from './partials/NewEvidenceTemplateModal'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getEvidenceTemplates } from './services'
import { handleError } from '../../helpers'

export default function EvidenceTemplatesList() {
	const { isConnected } = useSelector(state => state.offline)
	const [evidenceTemplates, setEvidenceTemplates] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [hideDocuments, setHideDocuments] = useState(false)
	const [hidePhotos, setHidePhotos] = useState(false)
	const [showNewTemplateModal, setShowNewTemplateModal] = useState(false)

	useEffect(() => {
		!evidenceTemplates && isConnected && getEvidenceTemplates({
			'filter[hide_documents]': hideDocuments ? 1 : null,
			'filter[hide_photos]': hidePhotos ? 1 : null,
			[`filter[${filterType}]`]: filterValue,
			include: 'instalationTypes'
		})
			.then(res => setEvidenceTemplates(res.data.data))
			.catch(error => handleError(error))
	}, [ evidenceTemplates, hideDocuments, hidePhotos, filterType, filterValue, isConnected ])

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Plantillas de Evidencias Solicitadas"
				subtitle="Documentos y fotográfias asociadas a tareas de verificación"
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				reload={() => setEvidenceTemplates(null)}
				ctaText="Agregar Plantilla"
				handleCtaClick={() => setShowNewTemplateModal(true)}
			/>
			<Card>
				<CardBody>
					<Switch
						checked={!hideDocuments} 
						onChange={() => {
							setHideDocuments(!hideDocuments)
							setEvidenceTemplates(null)
						}}
					/>{" "}Mostrar Documentos
					<Switch 
						checked={!hidePhotos} 
						onChange={() => {
							setHidePhotos(!hidePhotos)
							setEvidenceTemplates(null)
						}}
						className='ml-20' 
					/>{" "}Mostrar Fotográfias
					<EvidenceTemplatesTable 
						evidenceTemplates={evidenceTemplates}
						reload={() => setEvidenceTemplates(null)}
					/>
				</CardBody>
			</Card>
			{ showNewTemplateModal && (
				<NewEvidenceTemplateModal 
					visible
					onClose={() => setShowNewTemplateModal(false)}
					reload={() => setEvidenceTemplates(null)}
				/>
			)}
		</React.Fragment>
	)
}