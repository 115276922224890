import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import DecisionNoticePreview from './DecisionNoticePreview'

export default function DecisionNotices() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/decision_notices/preview/:id" component={DecisionNoticePreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}