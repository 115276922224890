import React, { useState, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'

export default function ServerSideSelect({ fetchOptions, debounceTimeout = 800, scopeId, ...props }) {
	const [fetching, setFetching] = useState(false)
	const [options, setOptions] = useState([])
	const fetchRef = useRef(0)
	const token = useSelector(state => state.auth.token)
	
	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setOptions([])
			setFetching(true)
			fetchOptions(value, token, scopeId).then((newOptions) => {
				if (fetchId !== fetchRef.current) return
				setOptions(newOptions)
				setFetching(false)
			})
		}

		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout, token, scopeId]);
	
	return (
		<Select
			labelInValue
			filterOption={false}
			showSearch
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
		/>
	);
}