import React from 'react'

import RETRY_NOTICE_TEMPLATE_INS01 from './templates/RETRY_NOTICE_TEMPLATE_INS01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function RetryNoticeDocument({ retryNotice }) {
	
	switch (retryNotice.format_version.format_template) {
		case 'RETRY_NOTICE_TEMPLATE_INS01':
			return <RETRY_NOTICE_TEMPLATE_INS01 data={retryNotice} />
	
		default:
			return <DocumentTemplate404 />
	}

}