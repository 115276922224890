import dewarApi, { getDomain } from '../../services/dewarApi'

export const getPlanTasks = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/plan_tasks', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storePlanTask = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/plan_tasks', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updatePlanTask = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/plan_tasks/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deletePlanTask = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/plan_tasks/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchPlanTasks = async (name, token) => (
	fetch( getDomain()+`/plan_tasks?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(plan_task => ({
				label: plan_task.name,
				value: plan_task.id,
			})),
		)
)