import React from 'react'

import IMPARTIALITY_NOTICE_TEMPLATE_INS01 from './templates/IMPARTIALITY_NOTICE_TEMPLATE_INS01'

import DocumentTemplate404 from '../../../components/DocumentTemplate404'

export default function ImpartialityNoticeDocument({ serviceReview }) {
	
	switch (serviceReview.impartiality_notice.format_version.format_template) {
		case 'IMPARTIALITY_NOTICE_TEMPLATE_INS01':
			return <IMPARTIALITY_NOTICE_TEMPLATE_INS01 data={serviceReview} />
	
		default:
			return <DocumentTemplate404 />
	}

}