import React from 'react'

import SERVICE_NOTIFICATION_TEMPLATE_INS01 from './templates/SERVICE_NOTIFICATION_TEMPLATE_INS01';
import SERVICE_NOTIFICATION_TEMPLATE_PRU01 from './templates/SERVICE_NOTIFICATION_TEMPLATE_PRU01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function ServiceNotificationDocument({ serviceNotification }) {
	
	switch (serviceNotification.format_version.format_template) {
		case 'SERVICE_NOTIFICATION_TEMPLATE_INS01':
			return <SERVICE_NOTIFICATION_TEMPLATE_INS01 data={serviceNotification} />

		case 'SERVICE_NOTIFICATION_TEMPLATE_PRU01':
			return <SERVICE_NOTIFICATION_TEMPLATE_PRU01 data={serviceNotification} />
	
		default:
			return <DocumentTemplate404 />
	}

}