import React from 'react'
import styled from 'styled-components'

import DocumentHeader from '../../../../components/DocumentHeader'
import ListDocumentTask from '../ListDocumentTask'

export default function VERIFICATION_LIST_TEMPLATE_INS01({ data }) {
	return (
		<Document>
			<DocumentHeader 
				title={`Listado de Verificación - ${data.service.proposal.inquiry.branch_spec.branch.instalation_type_name}`}
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
				proposalSku={data.proposal_sku}
			/>
			<table className='table table-striped table-sm'>
				<thead>
					<tr>
						<th className='pb-10 text-center regular'>El presente formato es entregado para desarrollo exclusivo de la labor o servicio que han sido contratados. Su uso no autorizado para servicios personales o para ser dado a conocer a terceras personas constituye infracción de la confidencialidad y al secreto empresarial. No podrá ser copiado o rediseñado sin autorización expresa del titular DEWAR SAS</th>
					</tr>
					<tr>
						<th className='pb-10 text-center regular'>*NA: No aplica / C: Conforme/ NC: No conforme / NCP: No conformidad con Plazo</th>
					</tr>
				</thead>
			</table>
			{ data.verification_tasks.map( task => (
				<ListDocumentTask 
					key={task.id}
					task={task}
				/>
			)) }
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	table {
		margin-top: 15px;
	}
`