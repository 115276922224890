import dewarApi, { getDomain } from '../../services/dewarApi'

export const getReportEvidence = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/report_evidence', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSingleReportEvidence = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/report_evidence/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReportEvidence = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/report_evidence', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReportEvidence = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/report_evidence/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReportEvidence = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/report_evidence/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReportEvidence = async (name, token) => (
	fetch( getDomain()+`/report_evidence?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(report_evidence => ({
				label: report_evidence.name,
				value: report_evidence.id,
			})),
		)
)