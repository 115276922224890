import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { message } from 'antd'

import ServiceVeredictDocument from './partials/ServiceVeredictDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getServiceVeredict, updateServiceVeredict } from './services'
import { handleError } from '../../helpers'
import { Button } from 'reactstrap'

export default function ServiceVeredictPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.match.params.id
	const user = useSelector(state => state.auth.user)
	const [serviceVeredict, setServiceVeredict] = useState(null)
	const { handleSubmit, setValue } = useForm({})
	

	useEffect(() => {
		!serviceVeredict && isConnected && getServiceVeredict(id, {
			include: 'serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,serviceReport.serviceReview.servicePlan.serviceInspector.user,formatVersion',
			append: 'initial_inspection_at,retry_inspection_at'
		})
			.then(res => {
				setServiceVeredict(res.data.data)
				let checklist = JSON.parse(res.data.data.checklist)
				Object.keys(checklist).forEach(key => {
					setValue(key, checklist[key])
				})
			})
			.catch(error => handleError(error))
	}, [serviceVeredict, id, setValue, isConnected])

	const onChecklistSubmit = values => {
		updateServiceVeredict(id, {
			checklist: JSON.stringify(values)
		})
			.then(res => message.success(res.data.message))
			.catch(error => handleError(error))
	}

	if(!isConnected) return <InternetRequired />

	if(!serviceVeredict) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ServiceVeredictDocument serviceVeredict={serviceVeredict} />
				<div className="no-print">
					<Button 
						color="primary" 
						className="mt-10"
						onClick={handleSubmit(onChecklistSubmit)}
						disabled={user.id !== serviceVeredict.service_report.director_user_id}
					>
						Guardar Cambios
					</Button>
				</div>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`