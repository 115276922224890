import dewarApi, { getDomain } from '../../services/dewarApi'

export const getEvidenceTemplates = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/evidence_templates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getEvidenceTemplate = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/evidence_templates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeEvidenceTemplate = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/evidence_templates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateEvidenceTemplate = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/evidence_templates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteEvidenceTemplate = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/evidence_templates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchEvidenceTemplates = async (name, token, taskTemplateId) => (
	fetch( getDomain()+`/evidence_templates?filter[item]=${name}&filter[detached_task_template_id]=${taskTemplateId}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(evidence_template => ({
				label: `[${evidence_template.item}] ${evidence_template.name}`,
				value: evidence_template.id,
			})),
		)
)

export const toggleInstalationType = (id, instalationTypeId) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/evidence_templates/${id}/instalation_types/${instalationTypeId}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchEvidenceTemplatesByItem = async (item, token) => (
	fetch( getDomain()+`/evidence_templates?filter[item]=${item}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(evidence_template => ({
				label: `[${evidence_template.type_name}] ${evidence_template.item} - ${evidence_template.name}`,
				value: evidence_template.id,
			})),
		)
)