import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Badge } from 'reactstrap'
import { Divider, message, Popconfirm, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

import EnableInspectionOfflineModal from './EnableInspectionOfflineModal'
import EnableTestOfflineModal from './EnableTestOfflineModal'
import { removeService, syncService } from '../../Services/actions'

import { getForSyncService } from '../../Services/services'
import { handleError } from '../../../helpers'

export default function ServiceOfflineStatus({ service }) {
	const offlineServicesIds = useSelector(state => state.services.services_ids)
	const offlineServices =  useSelector(state => state.services)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [enableSyncModal, setEnableSyncModal] = useState(null)

	const handleEnable = () => {		
		if(offlineServicesIds.length === 4) 
			return message.warning('Solo puede tener 4 servicios para offline. Inabilite los servicios que no estan en uso.')

		setEnableSyncModal(service.id)
	}

	const handleRefresh = () => {
		setLoading(true)
		getForSyncService(service.id)
			.then(res => dispatch(syncService(res.data.data)))
			.then(() => message.success('Servicio actualizado para su uso offline'))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}
	
	const handleDisable = () => {
		setLoading(true)
		dispatch(removeService(service.id))
		setLoading(false)
	}

	return (
		<React.Fragment>
			{offlineServicesIds.includes(service.id) ? (
				<div className='inline-block'>
					<Tooltip title="Haga click para desabilitar">
						<Popconfirm
							title="Ya no podrá usar este servicio offline"
							okText="Desabilitar"
							okButtonProps={{ danger: true }}
							onConfirm={handleDisable}
							disabled={loading}
						>
							<Badge style={{ cursor: 'pointer' }} color='success'>Habilitado Offline</Badge>
						</Popconfirm>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Actualizar Info" onClick={handleRefresh}>
						<FontAwesomeIcon className='text-link' icon={faRedo} />
					</Tooltip>
					<p className='small hint-text mb-0 mt-5'>Último {offlineServices.data.filter(s => s.id === service.id)[0].sync_at}</p>
				</div>
			) : (
				<Tooltip title="Haga click para habilitar" onClick={handleEnable}>
					<Badge style={{ cursor: 'pointer' }} color="warning">No disponible Offline</Badge>
				</Tooltip>
			)}
			{ enableSyncModal && (
				<React.Fragment>
					{ service.business_scope === 'inspeccion' ? (
						<EnableInspectionOfflineModal 
							visible
							onClose={() => setEnableSyncModal(null)}
							serviceId={enableSyncModal}
						/>
					) : (
						<EnableTestOfflineModal 
							visible
							onClose={() => setEnableSyncModal(null)}
							serviceId={enableSyncModal}
						/>
					)}
				</React.Fragment>
			)}
		</React.Fragment>
	)
}