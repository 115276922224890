import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { Descriptions, Table } from 'antd'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'

const { Item } = Descriptions

export default function SERVICE_REVIEW_TEMPLATE_INS01({ data, serviceData = null }) {
	const service = serviceData ? serviceData : data.service_plan.service_inspector.service

	return (
		<Document>
			<DocumentHeader 
				title="ACTA DE INSPECCIÓN"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
				proposalSku={service.proposal.sku}
				logoSrc={logo}
			/>
			<p className='section_title'>1. INFORMACIÓN GENERAL</p>
			<Descriptions column={2} bordered size='small'>
				<Item label="Fecha de inspección:">
					{ data.service_plan.inspected_at ? moment(data.service_plan.inspected_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin fecha</em> }
				</Item>
				<Item label="N° Asignación inspección">
					{data.service_plan.service_inspector.sku}
				</Item>
				<Item label="Inspector asignado:">
					{service.service_inspector.user.name}
				</Item>
				<Item label="Correo electrónico:">
					{service.service_inspector.user.email}
				</Item>
				<Item label="Tipo de inspección" span={2}>
					{service.type}
				</Item>
				<Item label="Criterio de la Inspección:" span={2}>
					{service.criteria}
				</Item>
			</Descriptions>
			<p className='section_title'>2. INFORMACIÓN INSTALACIÓN</p>
			<Descriptions column={2} bordered size='small'>
				<Item label="Razón Social:">
					{service.proposal.inquiry.branch_spec.branch.company.name}
				</Item>
				<Item label="NIT">
					{service.proposal.inquiry.branch_spec.branch.company.complete_document}
				</Item>
				<Item label="Representante Legal:" span={2}>
					{service.proposal.inquiry.branch_spec.branch.company.legal_owner_name}
				</Item>
				<Item label="Nombre de Instalación:">
					{service.proposal.inquiry.branch_spec.branch.name}
				</Item>
				<Item label="Tipo de Instalación:">
					{service.proposal.inquiry.branch_spec.branch.instalation_type_name}
				</Item>
				<Item label="Correo electrónico">
					{service.proposal.inquiry.branch_spec.branch.email}
				</Item>
				<Item label="Tipo de Servicio (Público o Privado):"></Item>
				<Item label="Dirección:">
					{service.proposal.inquiry.branch_spec.branch.address_line}
				</Item>
				<Item label="Ciudad:">
					{service.proposal.inquiry.branch_spec.branch.address_state},{" "}
					{service.proposal.inquiry.branch_spec.branch.address_city}
				</Item>
				<Item label="Teléfono Fijo:">
					{service.proposal.inquiry.branch_spec.branch.phone}
				</Item>
				<Item label="Celular:">
					{service.proposal.inquiry.branch_spec.branch.mobile}
				</Item>
			</Descriptions>
			<p className='section_title'>2.1. INFORMACIÓN COMPLEMENTARIA</p>
			<Descriptions column={2} bordered size='small'>
				<Item label="Nombre de quien recibe la inspección*:">
					{data.received_by ? `${service.proposal.inquiry.employee.name} / ${data.received_by}` : service.proposal.inquiry.employee.name}
				</Item>
				<Item label="Correo de quien recibe la inspección*:">
					{data.received_by_email ? data.received_by_email : 'NA'}
				</Item>
				<Item label="Cargo:">
					{service.proposal.inquiry.employee.position}
				</Item>
				<Item label="Correo electrónico:">
					{service.proposal.inquiry.employee.email}
				</Item>
				<Item label="Celular/Telf.:">
					{service.proposal.inquiry.employee.phone}{" - "}
					{service.proposal.inquiry.employee.mobile}
				</Item>
				<Item label="Código SICOM:">
					{service.proposal.inquiry.branch_spec.branch.sicom_code}
				</Item>
				<Item label="Bandera:">
					{service.proposal.inquiry.branch_spec.branch.flag}
				</Item>
			</Descriptions>
			<p className='section_title'>3. DECLARACIÓN DE INDENPENDENCIA DEL INSPECTOR</p>
			<div className='details_container'>
				"DECLARACION DE IMPARCIALIDAD, INDEPENDENCIA, CONFIDENCIALIDAD Y EXONERACIÓN DE CONFLICTO DE INTERÉS: En calidad de inspector para ejecución de la inspección, mayor de edad e identificado con la cedula de ciudadanía relacionada, declaro que  hasta dos (2) años previos a la realización de la presente inspección, NO he tenido relación comercial y/o he realizado actividades de: -Diseño o suministro de productos a la instalación a verificar -Aconsejar o brindar servicios de consultoría a los solicitantes, en aspectos relacionados con la inspección solicitada - Suministrar cualquier otro producto o servicio que pueda comprometer la confidencialidad, objetividad, imparcialidad o independencia de su proceso de certificación y decisiones. También declaro que en el proceso de inspección seré imparcial y manejaré la información observada en la instalación de manera confidencial con lo cual me comprometo a seguir los lineamientos establecidos por el Organismo de Inspección DEWAR SAS, para el desarrollo del proceso de inspección tales como procedimientos, guías y formularios con el fin de no poner en riesgo el nombre de la firma que represento. Así mismo, declaró no presentar conflicto de interés con la instalación que me ha sido asignada como inspector, y me comprometo a su realización bajo el cumplimiento de los procedimientos de DEWAR como Organismo de inspecciòn - OIN, y el Reglamento de inspección."
			</div>
			<Descriptions column={2} bordered size='small'>
				<Item label="Nombre:">
					{service.service_inspector.user.name}
				</Item>
				<Item label="Cedula:">
					{service.service_inspector.user.document}
				</Item>
			</Descriptions>
			<p className='section_title'>4. EJECUCIÓN DE LA INSPECCIÓN</p>
			<p>REUNIÓN DE APERTURA Y CIERRE</p>
			<Row>
				<Col>
					<div className='details_container'>
						<ol>
							<li>Presentación de las partes. Explicar el rol del inspector asignado, quien estará frente al proceso de inspección. Es el único responsable de DEWAR para aprobación y verificación del plan de acción y determinar el cierre de no conformidades.</li>
							<li>Alcance de la inspección. </li>
							<li>Presentación del servicio de inspección– Explicar procedimiento de inspección, los tiempos del proceso y la revisión y toma de decisión.</li>
							<li>Ratificación confidencialidad.</li>
							<li>Ratificación imparcialidad, Independencia y Transparencia</li>
							<li>Coordinación de recursos y materiales necesarios para la inspección.</li>
							<li>Consultar sobre acciones que se deben tener encuenta para el desarrollo de la inspección de manera segura.</li>
							<li>Preguntas y respuestas.</li>
						</ol>
					</div>
				</Col>
				<Col>
					<div className='details_container'>
						<ol>
							<li>Agradecimientos.</li>
							<li>Presentación general de los resultados.</li>
							<li>Entrega de Resultado del proceso, de existir  NO CONFORMIDADES se entrega el reporte de las mismas.</li>
							<li>Correecciones y tiempos de entrega, consecuencias de no recibirlas. El responsable de atender la inspección se compromete a enviar las correcciones. Explicación de inspección complementaria si aplica</li>
							<li>Procedimiento de Apelaciones, quejas o reclamo. Referencia Reglamento de Inspección.</li>
							<li>Quien toma la decisión, como se le comunica la decisión.</li>
						</ol>
					</div>
				</Col>
			</Row>
			<p className='section_title'>4.1. INFORMACIÓN DE SEGURIDAD</p>
			<div className='ant-table ant-table-small ant-table-empty'>
				<table className='table'>
					<thead className='ant-table-thead'>
						<tr>
							<th className='ant-table-cell'>ASPECTOS A EVALUAR</th>
							<th className='ant-table-cell'>RESPUESTA</th>
							<th className='ant-table-cell'>OBSERVACIONES</th>
						</tr>
					</thead>
					<tbody className='ant-table-tbody'>
						<tr>
							<td className='ant-table-cell'>1. ¿Conoce las acciones que se deben adelantar en caso de emergencia?</td>
							<td className='ant-table-cell'>{parseInt(data.review_specs.has_security_actions) ? 'Si' : 'No'}</td>
							<td className='ant-table-cell'>{data.review_specs.security_actions_comments}</td>
						</tr>
						<tr>
							<td className='ant-table-cell'>2. ¿Se cuenta con las condiciones necesarias para realizar la inspección de manera segura?</td>
							<td className='ant-table-cell'>{parseInt(data.review_specs.has_security_conditions) ? 'Si' : 'No'}</td>
							<td className='ant-table-cell'>{data.review_specs.security_conditions_comments}</td>
						</tr>
						<tr>
							<td className='ant-table-cell'>3. ¿Requiere de algún elemento adicional para realizar la inspección de forma segura?</td>
							<td className='ant-table-cell'>{parseInt(data.review_specs.has_security_additionals) ? 'Si' : 'No'}</td>
							<td className='ant-table-cell'>{data.review_specs.security_additionals_comments}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<p className='section_title'>5. LEVANTAMIENTO NO CONFORMIDADES</p>
			<div className='details_container'>
				Se recuerda que una NO CONFORMIDAD es un incumplimiento a un requisito de las normas que constituyen los criterios de inspección, las NO CONFORMIDADES tienen plazo máximo de cierre de (90) días calendario contados a partir de la fecha de la reunión de cierre de la inspección. Al finalizar el plazo mencionado el OIN, cerrará el proceso. Tenga en cuenta, que dependiendo de las NO CONFORMIDADES detectadas, el OIN, podrá, realizar cobros adicionales por concepto de inspección complementaria, tanto de la documentación, como de los elementos sujetos inspección y que sea necesario verificar para el cierre.
			</div>
			<p className='section_title'>6. CARACTERÍSTICAS TÉCNICAS DE LA INSTALACIÓN</p>
			<Descriptions column={3} bordered size='small'>
				<Item label="Tanques Enterrados">
					{ data.total_tanks_underground } tanques
				</Item>
				<Item label="Tanques En Superficie">
					{ data.total_tanks_ground } tanques
				</Item>
				<Item label="Capacidad Total (GL)">
					{ data.total_tanks_capacity } GL
				</Item>
			</Descriptions>
			<Table
				dataSource={data.tank_specs}
				columns={[
					{
						title: 'No Tanque',
						dataIndex: 'tank_number'
					},
					{
						title: 'Producto Almacenado',
						dataIndex: 'product_name',
						render: (t,r) => `${t}${r.other_product_name ? ` - ${r.other_product_name}` : ''}`
					},
					{
						title: 'Capacidad (GL)',
						dataIndex: 'tank_capacity'
					},
					{
						title: 'Diametro y Tipo (Plantas)',
						render: (t, r) => r.tank_diameter ? `Diametro: ${r.tank_diameter} Tipo: ${r.tank_type}` : 'NA'
					},
					{
						title: 'Tipo',
						dataIndex: 'is_underground',
						render: t => t ? 'Tanque Enterrado' : 'En Superficie'
					},
				]}
				size="small"
				pagination={false}
				rowKey={r => r.id}
			/>
			<Table 
				dataSource={data.island_specs}
				columns={[
					{
						title: 'No Isla',
						dataIndex: 'island_number'
					},
					{
						title: 'No. Equipos de suministro',
						dataIndex: 'equipos_suministro_total',
					},
					{
						title: 'No. Mangueras',
						dataIndex: 'mangueras_total'
					}
				]}
				size="small"
				pagination={false}
				rowKey={r => r.id}
			/>
			<p className='section_title'>7. INSPECCIÓN</p>
			<p className='section_title'>7.1. EQUIPOS DE MEDICIÓN EMPLEADOS EN LA INSPECCIÓN</p>
			<Table 
				dataSource={data.review_equipments}
				columns={[
					{
						title: 'Item',
						dataIndex: 'item'
					},
					{
						title: 'Equipo',
						dataIndex: 'name'
					},
					{
						title: 'Marca',
						dataIndex: 'brand'
					},
					{
						title: 'Serial',
						dataIndex: 'serial'
					},
					{
						title: 'Código Interno',
						dataIndex: 'code'
					},
					{
						title: 'Fecha de calibración / verificación',
						dataIndex: 'verified_at',
						render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
					},
				]}
				size="small"
				pagination={false}
				rowKey={r => r.id}
			/>
			<p className='section_title'>7.2 REPORTE DE NO CONFORMIDADES</p>
			<Descriptions column={2} bordered size='small' className='mb-10'>
				<Item label="Se presentaron No conformidades">
					{ data.total_nc_tasks > 0 ? 'Si' : 'No' }
				</Item>
				<Item label="Se requiere inspección de cierre">
					{ data.total_nc_tasks > 0 ? 'Si' : 'No' }
				</Item>
			</Descriptions>
			<Descriptions column={2} bordered size='small' className='mb-10'>
				<Item span={2} label="Número de hallazgos No conformes identificados pendientes Inspección Cierre">
					{ data.total_nc_tasks }
				</Item>
				<Item span={2} label="Número de No conformidades identificadas con plazo de atención Resolución 40198 de 2021 - No exigible para esta inspección">
					{ data.total_ncp_tasks }
				</Item>
				{ !service.initial_service && (
					<Item span={2} label="Plazo máximo para cierre de No conformidades">
						{data.nc_deadline_at ? moment(data.nc_deadline_at).format('DD-MMM-YYYY').toUpperCase() : 'NA'}
					</Item>
				)}
				<Item span={2} label="Observaciones del inspector:">
					{data.observations}
				</Item>
			</Descriptions>
			<p className='section_title'>7.3. RESULTADO DE LA INSPECCIÓN - Los resultados indicados están sujetos a la revisión y decisión final del Comité Técnico una vez este revise el informe de inspección y soportes correspondientes.</p>
			<Descriptions bordered size="small">
				<Item label="Resultado">
					{data.is_compliant === 1 ? 'Conforme' : data.is_compliant === 0 ? 'No Conforme' : <em>Sin Registro</em> }
				</Item>
			</Descriptions>
			<p className='section_title'>8.  RATIFICACIÓN DEL PRINCIPIO DE IMPARCIALIDAD E INDEPENDENCIA</p>
			<Descriptions bordered size="small">
				<Item label="Si la instalación ha recibido servicios por alguna de las entidades relacionadas identificadas previamente por  DEWAR, escriba el nombre y diligencie la Ratificación del principio de imparcialidad e independencia FRN34">
					{data.external_inspector}
				</Item>
			</Descriptions>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 15px;
	}
`