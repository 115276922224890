import dewarApi, { getDomain } from '../../services/dewarApi'

export const getTanks = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/tanks', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getTank = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/tanks/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTank = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/tanks', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTank = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/tanks/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTank = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/tanks/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchTanks = async (name, token) => (
	fetch( getDomain()+`/tanks?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(tank => ({
				label: tank.name,
				value: tank.id,
			})),
		)
)