import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

import { departments } from './departments'

export default function DepartmentAndCityForm({ register, errors, watch, current }) {
	const selectedState = watch('address_state')

	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Departamento <span className="text-danger">*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('address_state', { required:true })}
						defaultValue={current && current.address_state}
					>
						<option value="">:: Seleccione una opción ::</option>
						{ departments.map((department,i) => <option key={i}>{department.name}</option>) }
					</Form.Control>
					{ errors.address_state && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Ciudad / Municipio <span className="text-danger">*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('address_city', {required:true})} 
						defaultValue={current && current.address_city}
						disabled={!selectedState && !current}
					>
						<option value="">:: Seleccione una opción ::</option>
						{ departments.map(department => {
							if(department.name === selectedState || (!selectedState && current && department.name === current.address_state)){
								return (
									<React.Fragment key={department.name}>
										{department.cities.map((city,n) => <option key={n}>{city}</option> )}
									</React.Fragment>
								)
							} else {
								return null
							}
						})}
					</Form.Control>
					{ errors.address_city && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}