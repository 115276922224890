import React from 'react'
import styled from 'styled-components'

export default function PageTitle({ title, subtitle }) {
	return (
		<Container>
			<h4 className="mb-0">{title}</h4>
			<p className="mb-0">{subtitle}</p>
		</Container>
	)
}

const Container = styled.div`
	padding: 20px 20px;
	background-color: #fff;
	margin-bottom: 20px;
`