import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ServiceInspectorPreview from './ServiceInspectorPreview'

export default function ServiceInspectors() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/service_inspectors/preview/:id" component={ServiceInspectorPreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}