import dewarApi from '../../services/dewarApi'

export const getEmailLogs = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/email_logs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getEmailLog = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/email_logs/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeEmailLog = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/email_logs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateEmailLog = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/email_logs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteEmailLog = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/email_logs/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)