import dewarApi, { getDomain } from '../../services/dewarApi'

export const getBranchSpecs = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/branch_specs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeBranchSpec = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/branch_specs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateBranchSpec = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/branch_specs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteBranchSpec = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/branch_specs/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchBranchSpecs = async (name, token) => (
	fetch( getDomain()+`/branch_specs?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(branch_spec => ({
				label: branch_spec.name,
				value: branch_spec.id,
			})),
		)
)