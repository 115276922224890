import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import CertificatePreview from './CertificatePreview'
import CertificatesList from './CertificatesList'

export default function Certificates() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/certificates" component={CertificatesList} />
				<Route exact path="/certificates/preview/:id" render={(props) => <CertificatePreview isAdmin id={props.match.params.id} />} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}