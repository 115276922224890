import dewarApi, { getDomain } from '../../services/dewarApi'

export const getServiceInspectors = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/service_inspectors', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceInspector = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/service_inspectors/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetServiceInspector = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/service_inspectors/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceInspector = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/service_inspectors', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceInspector = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/service_inspectors/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceInspector = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/service_inspectors/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceInspectors = async (name, token) => (
	fetch( getDomain()+`/service_inspectors?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_inspector => ({
				label: service_inspector.name,
				value: service_inspector.id,
			})),
		)
)