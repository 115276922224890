import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Alert, message } from 'antd'
import moment from 'moment'

import { updateServiceInspector } from '../services'
import { handleError } from '../../../helpers'

export default function AcceptServiceModal({ visible, onClose, serviceInspector, reload }) {

	const onSubmit = () => {
		updateServiceInspector(serviceInspector.id, { 
			accepted_at: moment().format('YYYY-MM-DD HH:mm:ss') 
		})
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible}>
			<ModalHeader toggle={onClose}>Aceptación de Asignación</ModalHeader>
			<ModalBody>
				<Alert description="Al aceptar la asignación no podrá reversar este proceso" type='info' />
				<Button color="primary" className='mt-10' onClick={onSubmit}>
					Aceptar la Inspección
				</Button>
			</ModalBody>
		</Modal>
	)
}