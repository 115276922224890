import dewarApi, { getDomain } from '../../services/dewarApi'

export const getInstalationTypes = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/instalation_types', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeInstalationType = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/instalation_types', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateInstalationType = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/instalation_types/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteInstalationType = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/instalation_types/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchInstalationTypes = async (name, token) => (
	fetch( getDomain()+`/instalation_types?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(instalation_type => ({
				label: instalation_type.name,
				value: instalation_type.id,
			})),
		)
)