import React from 'react'
import { Descriptions } from 'antd'

import { text } from '../../../helpers'

const { Item } = Descriptions

export default function CompanyDetails({ company }) {
	return (
		<React.Fragment>
			<Descriptions bordered size="small" className="mt-10 mb-10" >
				<Item label="Razón Social">
					{company && company.name}
				</Item>
				<Item label="NIT y Dígito de Verificación">
					{company && company.complete_document}
				</Item>
				<Item label="Representante Legal">
					{company && company.legal_owner_name}
				</Item>
				<Item label="Dirección">
					{company && company.address_line}
				</Item>
				<Item label="Departamento">
					{company && company.address_state}
				</Item>
				<Item label="Ciudad / Municipio">
					{company && company.address_city}
				</Item>
				<Item label="Correo Electrónico(s)">
					{company && `${company.email} ${company.email_secundary ? company.email_secundary : ''} ${company.email_terciary ? company.email_terciary : ''}`}
				</Item>
				<Item label="Teléfono">
					{company && text(company.phone)}
				</Item>
				<Item label="Celular">
					{company && company.mobile}
				</Item>
				<Item label="Agente retenedor de retefuente">
					{(company && company.is_autorretenedor) ? 'SI' : 'NO'}
				</Item>
				<Item label="Correo Electrónico Facturación">
					{company && company.invoicing_email}
				</Item>
				<Item label="Dirección Envío Certificado">
					{company && company.certificate_address_line}
				</Item>
			</Descriptions>
		</React.Fragment>
	)
}