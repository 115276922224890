import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { InputGroup } from 'react-bootstrap'
import { message, Popconfirm, Table } from 'antd'

import ServerSideSelect from '../../components/ServerSideSelect'

import { deleteReportDefaultEvidence, getReportDefaultEvidence, storeReportDefaultEvidence } from './services'
import { searchEvidenceTemplatesByItem } from '../EvidenceTemplates/services'
import { handleError } from '../../helpers'

export default function ReportDefaultEvidencesModal({ visible, onClose, instalationType }) {
	const [reportDefaultEvidences, setReportDefaultEvidences] = useState(null)
	const [selectedEvidenceTemplate, setSelectedEvidenceTemplate] = useState([])

	useEffect(() => {
		!reportDefaultEvidences && getReportDefaultEvidence({
			'filter[instalation_type_id]': instalationType.id,
			include: 'evidenceTemplate'
		})
			.then(res => setReportDefaultEvidences(res.data.data))
			.catch(error => handleError(error))
	}, [ reportDefaultEvidences, instalationType ])

	const onSubmit = () => {
		storeReportDefaultEvidence({ 
			instalation_type_id: instalationType.id, 
			evidence_template_id: selectedEvidenceTemplate.value
		})
			.then(res => {
				message.success(res.data.message)
				setReportDefaultEvidences(null)
				setSelectedEvidenceTemplate([])
			})
			.catch(error => handleError(error))
	}

	const handleDelete = id => {
		deleteReportDefaultEvidence(id)
			.then(res => {
				message.success(res.data.message)
				setReportDefaultEvidences(null)
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} size="lg">
			<ModalHeader toggle={onClose}>Evidencia por defecto en Informe</ModalHeader>
			<ModalBody>
				<Table 
					dataSource={reportDefaultEvidences}
					loading={!reportDefaultEvidences}
					rowKey={r => r.id}
					columns={[
						{ title: 'Evidencia', dataIndex: 'evidence_template', render: t => `[${t.item}] ${t.name}` },
						{ title: 'Acciones', render: (t,r) => (
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger: true }}
								onConfirm={()=>handleDelete(r.id)}
							>
								<Button color="danger" size="sm" outline>Eliminar</Button>
							</Popconfirm>
						)}
					]}
					size="small"
				/>
				<InputGroup className='mt-10' >
					<ServerSideSelect 
						value={selectedEvidenceTemplate}
						placeholder="Buscar por item"
						fetchOptions={searchEvidenceTemplatesByItem}
						onChange={value => setSelectedEvidenceTemplate(value)}
						className="full-width mb-10"
					/>
					<Button 
						className='btn-inputgroup' 
						color="primary" 
						disabled={!selectedEvidenceTemplate.value} 
						onClick={onSubmit}
					>
						Asociar Evidencia
					</Button>
				</InputGroup>
			</ModalBody>
		</Modal>
	)
}