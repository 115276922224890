import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import { offlineUpdateReview } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'
import { updateServiceReview } from '../services'
import { handleError } from '../../../helpers'

export default function EditReviewModal({ visible, onClose, serviceReview, reload, service, fromServiceProfile = false }) {
	const { isConnected } = useSelector(state => state.offline)
	const dispatch = useDispatch()
	const { register, handleSubmit, formState: { errors } } = useForm({ 
		defaultValues: {
			nc_deadline_at: serviceReview.nc_deadline_at ? moment(serviceReview.nc_deadline_at).format('YYYY-MM-DD') : moment().add(90,'days').format('YYYY-MM-DD'),
			received_by: serviceReview.received_by ? serviceReview.received_by : service.proposal.inquiry.employee.name,
			received_by_email: serviceReview.received_by_email ? serviceReview.received_by_email : service.proposal.inquiry.employee.email,
			observations: serviceReview.observations ? serviceReview.observations : 'Se ha verificado los datos de la instalación y están igual que lo registrados en la solicitud. – De la información registrada en la solicitud se debe modificar: ____________________ - Es responsabilidad de la instalación el cumplimiento de los requisitos que tienen plazo de acuerdo con la Resolución 40198 de 2021.'
		} 
	})

	const onSubmit = values => {
		if(!fromServiceProfile){
			delete values.nc_deadline_at
			delete values.observations
		}

		if(isConnected){
			updateServiceReview(serviceReview.id, values)
				.then(res => {
					message.success(res.data.message)
					onClose()
					reload()
				})
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateReview(values, service.id))
			dispatch(queueRequest({ url: `/service_reviews/${serviceReview.id}`, data: values }))
			message.info('Actualización en espera de internet')
			onClose()
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Acta de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						{ !fromServiceProfile && (
							<Form.Group>
								<Form.Label>Plazo máximo para cierre de No conformidades <small>(opcional)</small></Form.Label>
								<Form.Control 
									{...register('nc_deadline_at', {})}
									type="date"
								/>
							</Form.Group>
						)}
						<Form.Group>
							<Form.Label>Nombre de quien recibe la inspección <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('received_by', { required: true })}
								placeholder="Escriba aquí..."
							/>
							{ errors.received_by && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> } 
						</Form.Group>
						<Form.Group>
							<Form.Label>Correo de quien recibe la inspección <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('received_by_email', { required: true })}
								placeholder="Escriba aquí..."
							/>
							{ errors.received_by_email && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> } 
						</Form.Group>
						{ !fromServiceProfile && (
							<Form.Group>
								<Form.Label>Observaciones del inspector <small>(opcional)</small></Form.Label>
								<Form.Control 
									as="textarea"
									{...register('observations')}
									placeholder='Escriba aquí...'
									style={{ height: '70px' }}
								/>
							</Form.Group>
						)}
						<Button color="primary" type="submit">Actualizar Datos</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}