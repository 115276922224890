import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, InputGroup } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeTicketNote } from '../../TicketNotes/services'
import { handleError } from '../../../helpers'

export default function NewTicketNoteForm({ ticket, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			ticket_id: ticket.id,
			user_id: currentUser.id
		}
	})

	const onSubmit = values => {
		setLoading(true)
		storeTicketNote(values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<InputGroup>
					<Form.Control 
						as="textarea"
						{...register('description', { required: true })}
						placeholder='Escriba aquí...'
						style={{ height: '50px' }}
						className='mb-0'
					/>
					<Button color='primary' disabled={loading} className='mt-0'>
						Enviar
					</Button>
				</InputGroup>
				{ errors.description && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form>
		</React.Fragment>
	)
}