import dewarApi, { getDomain } from '../../services/dewarApi'

export const getDecisionNotices = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/decision_notices', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getDecisionNotice = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/decision_notices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPublicDecisionNotice = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/decision_notices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeDecisionNotice = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/decision_notices', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateDecisionNotice = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/decision_notices/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteDecisionNotice = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/decision_notices/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchDecisionNotices = async (name, token) => (
	fetch( getDomain()+`/decision_notices?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(decision_notice => ({
				label: decision_notice.name,
				value: decision_notice.id,
			})),
		)
)

export const notifyDecisionNotice = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/decision_notices/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)