import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import UserEquipmentsTable from './partials/UserEquipmentsTable'
import NewUserEquipmentModal from './partials/NewUserEquipmentModal'

import { getUserEquipments } from './services'
import { handleError } from '../../helpers'

export default function UserEquipmentsModal({ visible, onClose, user }) {
	const [userEquipments, setUserEquipments] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!userEquipments && getUserEquipments({
			'filter[user_id]': user.id
		})
			.then(res => setUserEquipments(res.data.data))
			.catch(error => handleError(error))
	}, [ userEquipments, user ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} size='xl'>
				<ModalHeader toggle={onClose}>Equipos de Medición - {user.name}</ModalHeader>
				<ModalBody>
					<UserEquipmentsTable 
						userEquipments={userEquipments}
						reload={() => setUserEquipments(null)}
					/>
					<Button color="primary" outline onClick={() => setShowNewModal(true)} className='mt-10'>
						Agregar Equipo de Medición
					</Button>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewUserEquipmentModal 
					visible
					onClose={() => setShowNewModal(false)}
					userId={user.id}
					reload={() => setUserEquipments(null)}
				/>
			)}
		</React.Fragment>
	)
}