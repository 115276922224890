import React from 'react'
import styled from 'styled-components'
import { Descriptions } from 'antd'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import { currency } from '../../../../helpers'

const { Item } = Descriptions

export default function SERVICE_INSPECTOR_TEMPLATE_INS01({ data }) {
	return (
		<Container id="doc">
			<DocumentHeader 
				title="ASIGNACIÓN INSPECTOR"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
			/>
			<Descriptions column={2} bordered size='small'>
				<Item span={2} label="Inspector Asignado">{data.user.name}</Item>
				<Item label="Fecha envío asignación">{moment(data.assigned_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
				<Item label="Consecutivo asignación">
					ADN-{data.id}-{moment(data.assigned_at).format('MM-YYYY')}
				</Item>
				<Item label="Servicio de Inspección">$ {currency(data.service_value)}</Item>
				<Item label="Gastos de Viaje">$ {currency(data.trip_expenses)}</Item>
				<Item label="Código Proceso">{data.service.proposal.sku}</Item>
			</Descriptions>
			<h6>1. INFORMACIÓN DE LA INSTALACIÓN</h6>
			<Descriptions column={2} bordered size='small'>
				<Item label="Razón Social">{data.service.proposal.inquiry.branch_spec.branch.company.name}</Item>
				<Item label="NIT">{data.service.proposal.inquiry.branch_spec.branch.company.complete_document}</Item>
				<Item label="Representante Legal" span={2}>{data.service.proposal.inquiry.branch_spec.branch.company.legal_owner_name}</Item>
				<Item label="Nombre de Instalación">{data.service.proposal.inquiry.branch_spec.branch.name}</Item>
				<Item label="Tipo de Instalación">{data.service.proposal.inquiry.branch_spec.branch.instalation_type_name}</Item>
				<Item span={2} label="Correo Electrónico">{data.service.proposal.inquiry.branch_spec.branch.email}</Item>
				<Item label="Dirección">{data.service.proposal.inquiry.branch_spec.branch.address_line}</Item>
				<Item label="Ciudad">{data.service.proposal.inquiry.branch_spec.branch.address_state}, {data.service.proposal.inquiry.branch_spec.branch.address_city}</Item>
				<Item label="Teléfono">{data.service.proposal.inquiry.branch_spec.branch.phone}</Item>
				<Item label="Celular">{data.service.proposal.inquiry.branch_spec.branch.mobile}</Item>
			</Descriptions>
			<h6>2. INFORMACIÓN DE LA INSPECCIÓN</h6>
			<Descriptions column={3} bordered size='small'>
				<Item label="Tipo de Inspección" span={3}>{data.service.type}</Item>
				<Item label="Criterio" span={3}>{data.service.criteria}</Item>
				<Item label="Lugar">Sede de la instalación a inspeccionar</Item>
				<Item label="Horas Aprox.">{data.service.hours_estimate}</Item>
				<Item label="Fecha">{moment(data.service.start_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
				<Item span={3} label="Observadores">{data.service.observers}</Item>
			</Descriptions>
			<h6>3. OBSERVACIONES ESPECÍFICAS:</h6>
			<div className='details_container'>
				{data.observations}
			</div>
			<h6>4. ESPACIO PARA SER DILIGENCIADO POR EL INSPECTOR</h6>
			<div className='details_container'>
				<p><strong>DECLARACIÓN DE INDEPENDENCIA, IMPARCIALIDAD, CONFIDENCIALIDAD Y EXONERACIÓN DE CONFLICTO DE INTERÉS:</strong></p>
				<p>En calidad de inspector asignado a la actividad que soporta el presente documento, mayor de edad e identificado con la cedula de ciudadanía relacionada, declaro de hasta dos (2) años previos a la realización de la presente inspección, NO he tenido relación comercial y/o he realizado actividades de: </p>
				<ul>
					<li>Diseño o suministro de productos a la instalación a verificar, </li>
					<li>Aconsejar o brindar servicios de consultoría a los solicitantes, en aspectos relacionados con la  certificación solicitada. </li>
					<li>Suministrar cualquier otro producto o servicio que pueda comprometer la confidencialidad, objetividad o imparcialidad de su proceso de certificación y decisiones. </li>
				</ul>
				<p>También declaro que en el proceso de inspección seré imparcial y manejaré la información observada en la instalación de manera confidencial con lo cual me comprometo a seguir los lineamientos establecidos por el Organismo evaluador de conformidad DEWAR S.A.S., para el desarrollo del proceso de inspección tales como procedimientos, guías y formularios con el fin de no poner en riesgo el nombre de la firma que represento.</p>
				<p>Así mismo, declaró no presentar conflicto de interés con la instalación que me ha sido asignada como inspector, y me comprometo a su realización bajo el cumplimiento de los procedimientos del DEWAR como organismo de inspección y el Reglamento de Inspección. (En caso no de aceptación especificar el motivo).</p>
			</div>
			<div className='details_container pt-20 pb-10'>
				<p>Aceptación del Inspector: <strong>{ data.accepted_at ? moment(data.accepted_at).format('DD-MM-YYY') : <em>Sin aceptación aún</em> }</strong></p>
			</div>
			<p>Por favor una vez recibida esta asignación cuenta con máximo dos (2) días para su aceptación o rechazo, de ser aceptada deberá  elaborar y enviar del Plan de inspección en el Formato FRN16, teniendo en cuenta el documento DN10 Criterios de tiempos para la inspección y tener presente para la realización de la misma, el procedimiento de inspección PN2 y el Reglamento de Inspección DN5.</p>
			<p>Atentamente,</p>
			<p>Coordinadora de programación</p>
			<p>DEWAR - OIN</p>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fff;
	padding: 15px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
`