import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import NonCompliantReportPreview from './NonCompliantReportPreview'

export default function NonCompliantReports() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/non_compliant_reports/preview/:id" component={NonCompliantReportPreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}