import { message } from 'antd'
import React from 'react'
import { Row, Col, Form, InputGroup } from 'react-bootstrap'

import { BottomBar } from '../../../../components/WizardComponents'

export default function PastCertificationStep({ form, setForm, currentStep, setCurrentStep }) {

	const handleBackStep = () => {
		setCurrentStep(currentStep-1)
	}

	const handleNextStep = () => {
		if(form.has_previous_certification && !form.previous_competitor)
			return message.warning('Debe ingresar el nombre del organismo evaluador')
		if(form.has_previous_certification && !form.previous_start_at)
			return message.warning('Debe ingresar la fecha de emisión')
		
		setCurrentStep(currentStep+1)
	}

	return (
		<React.Fragment>
			<InputGroup className="mb-15">
				<InputGroup.Text>Tiene certificación anterior:</InputGroup.Text>
				<Form.Check 
					label="SI"
					onChange={e => setForm({ ...form, has_previous_certification: 1 })}
					checked={form.has_previous_certification}
					className="mr-15 ml-10 pt-8"
				/>
				<Form.Check 
					label="NO"
					onChange={e => setForm({ 
						...form, 
						has_previous_certification: 0,
						previous_competitor: '',
						previous_start_at: '',
						previous_end_at: ''
					})}
					checked={!form.has_previous_certification}
					className="mr-15 pt-8"
				/>
			</InputGroup>
			{ form.has_previous_certification ? (
				<React.Fragment>
					<InputGroup as={Col}>
						<InputGroup.Text>Nombre Organismo Evaluador de la Conformidad</InputGroup.Text>
						<Form.Control 
							value={form.previous_competitor}
							onChange={e => setForm({ ...form, previous_competitor: e.target.value })}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<Row className='mb-10'>
						<InputGroup as={Col}>
							<InputGroup.Text>Fecha emisión</InputGroup.Text>
							<Form.Control 
								value={form.previous_start_at}
								onChange={e => setForm({ ...form, previous_start_at: e.target.value })}
								type="date"
							/>
						</InputGroup>
					</Row>
				</React.Fragment>
			) : <React.Fragment></React.Fragment>}
			<BottomBar handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
		</React.Fragment>
	)
}