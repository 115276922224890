import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, InputGroup } from 'react-bootstrap'
import { message, Popconfirm, Table } from 'antd'

import { deleteCompanyEmail, getCompanyEmails, storeCompanyEmail } from './services'
import { handleError } from '../../helpers'

export default function CompanyEmailsModal({ visible, onClose, company }) {
	const [companyEmails, setCompanyEmails] = useState(null)
	const [email, setEmail] = useState('')
	const [category, setCategory] = useState('')

	useEffect(() => {
		!companyEmails && getCompanyEmails({
			'filter[company_id]': company.id
		})
			.then(res => setCompanyEmails(res.data.data))
			.catch(error => handleError(error))
	}, [ companyEmails, company ])

	const onSubmit = () => {
		storeCompanyEmail({ company_id: company.id, email, category })
			.then(res => {
				message.success(res.data.message)
				setCompanyEmails(null)
				setEmail('')
			})
			.catch(error => handleError(error))
	}

	const handleDelete = id => {
		deleteCompanyEmail(id)
			.then(res => {
				message.success(res.data.message)
				setCompanyEmails(null)
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} size="lg">
			<ModalHeader toggle={onClose}>Correos Electrónicos Asociados</ModalHeader>
			<ModalBody>
				<Table 
					dataSource={companyEmails}
					loading={!companyEmails}
					rowKey={r => r.id}
					size="small"
					columns={[
						{ title: 'Email', dataIndex: 'email' },
						{ title: 'Categoría', dataIndex: 'category', render: t => t ? t : <em>Sin categoría</em> },
						{ title: 'Acciones', render: (t,r) => (
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger: true }}
								onConfirm={()=>handleDelete(r.id)}
							>
								<Button color="danger" size="sm" outline>Eliminar</Button>
							</Popconfirm>
						)}
					]}
				/>
				<InputGroup className='mt-10' >
					<Form.Control 
						value={category}
						onChange={e => setCategory(e.target.value)}
						as="select"
					>
						<option value="">:: Categoria ::</option>
						<option>General</option>
						<option>Facturación</option>
						<option>Envio Certificado</option>
					</Form.Control>
					<Form.Control 
						value={email}
						onChange={e => setEmail(e.target.value.toLowerCase())}
						placeholder="Escriba aquí..."
					/>
					<Button className='btn-inputgroup' color="primary" disabled={!email} onClick={onSubmit}>
						Agregar Correo
					</Button>
				</InputGroup>
			</ModalBody>
		</Modal>
	)
}