import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Spinner } from 'reactstrap'
import { Tabs } from 'antd'
import { Redirect } from 'react-router'

import ServiceProfileDetails from './partials/ServiceProfileDetails'
import ServiceProfileTopBar from './partials/ServiceProfileTopBar'

import ServiceInspectorTab from '../ServiceInspectors/ServiceInspectorTab'
import ServiceNotificationTab from '../ServiceNotifications/ServiceNotificationTab'
import ServicePlanTab from '../ServicePlans/ServicePlanTab'
import ServiceReviewTab from '../ServiceReviews/ServiceReviewTab'
import NonCompliantReportTab from '../NonCompliantReports/NonCompliantReportTab'
import VerificationListTab from '../VerificationLists/VerificationListTab'
import ServiceResolutionTab from './ServiceResolutionTab'
import RetryNoticeTab from '../RetryNotices/RetryNoticeTab'
import ServiceEvidenceTab from '../Evidence/ServiceEvidenceTab'
import ReviewLogbookTab from '../ReviewLogbooks/ReviewLogbookTab'

import { getForSyncService } from './services'
import { handleError } from '../../helpers'
import ReportEvidenceList from '../ReportEvidence/ReportEvidenceList'

export default function ServiceProfile(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.match.params.id
	const user = useSelector(state => state.auth.user)
	const [service, setService] = useState(null)
	const [currentTab, setCurrentTab] = useState('asignacion')
	const [filters, setFilters] = useState({ onlyUncompliant: false, pendingTasks: { periods: [] }})
	
	useEffect(() => {
		!service && isConnected && getForSyncService(id)
			.then(res => setService(res.data.data))
			.catch(error => handleError(error))
	}, [ service, id, isConnected ])

	if(!isConnected) return <Redirect to={`/offline/services/${id}`} />

	if(!service) return <Spinner />

	const reload = () => setService(null)

	const serviceInspector = service.service_inspector
	const servicePlan = serviceInspector && serviceInspector.service_plan
	const serviceNotification = serviceInspector && serviceInspector.service_notification
	const serviceReview = servicePlan && servicePlan.service_review 
	const nonCompliantReport = serviceReview && serviceReview.non_compliant_report 
	const retryNotice = serviceReview && serviceReview.retry_notice 
	const verificationList = service.verification_list

	const tabProps = {
		service,
		reload,
		filters,
		setFilters
	}

	const generalItems = [
		{
			key: 'asignacion',
			label: '1. Asignación de Inspección',
			disabled: user.role.includes('director'),
			children: <ServiceInspectorTab {...tabProps} />,
		},
		{
			key: 'notificacion',
			label: '2. Notificación de Inspección',
			disabled: (!serviceInspector || !serviceInspector.accepted_at),
			children: <ServiceNotificationTab {...tabProps} />,
		},
		{
			key: 'plan',
			label: '3. Plan de Inspección',
			disabled: !serviceNotification,
			children: <ServicePlanTab {...tabProps} />,
		},
		{
			key: 'acta',
			label: '4. Acta de Inspección',
			disabled: !servicePlan,
			children: <ServiceReviewTab {...tabProps} />,
		},
	]

	const inspectionItems =  generalItems.concat([
		{
			key: 'lista',
			label: '5. Lista de Verificación',
			disabled: !serviceReview,
			children: <VerificationListTab {...tabProps} />,
		},
		{
			key: 'evidencias',
			label: service.type === 'Inicial' ? '6. Evidencias Relacionadas' : '6. Evidencias No Conformidades',
			disabled: !verificationList,
			children: <ServiceEvidenceTab {...tabProps} onlyNonCompliant={service.type === 'Cierre'} />,
		},
		{
			key: 'noconformidades',
			label: '7. Reporte No Conformidades',
			disabled: !(serviceReview && verificationList),
			children: <NonCompliantReportTab {...tabProps} />,
		},
		{
			key: 'informe',
			label: '8. Resultado de Inspección',
			disabled: !(nonCompliantReport && verificationList),
			children: <ServiceResolutionTab {...tabProps} />,
		}
	])

	if(!user.role.includes('inspector') && service.type === 'Inicial')
		inspectionItems.concat([
			{
				key: 'retrynotice',
				label: '9. Carta Inspección de Cierre',
				disabled: !(retryNotice || service.status.includes('_retry_needed')),
				children: <RetryNoticeTab {...tabProps} />
			}
		])

	const testItems = generalItems.concat([
		{
			key: 'bitacora',
			label: '5. Bitacoras de Inspección',
			disabled: !serviceReview,
			children: <ReviewLogbookTab {...tabProps} serviceReview={serviceReview} />,
		},
		{
			key: 'reportEvidence',
			label: '6. Evidencia Relacionada',
			disabled: !serviceReview,
			children: (
				<React.Fragment>
					<p className='bold mt-20'>EVIDENCIA RELACIONADA</p>
					<ReportEvidenceList
						serviceReportId={service.service_report_id}
						serviceReviewId={serviceReview && serviceReview.id}
						editable={service.is_editable}
						directory="report_evidence"
					/>
				</React.Fragment>
			)
		},
		{
			key: 'securityLogs',
			label: '7. Registros de Seguridad',
			disabled: !serviceReview,
			children: (
				<React.Fragment>
					<p className='bold mt-20'>REGISTROS DE SEGURIDAD</p>
					<ReportEvidenceList
						serviceReportId={service.service_report_id}
						serviceReviewId={serviceReview && serviceReview.id}
						editable={service.is_editable}
						directory="security_evidence"
					/>
				</React.Fragment>
			)
		}
	])

	return (
		<React.Fragment>
			<ServiceProfileTopBar service={service} reload={reload} setCurrentTab={setCurrentTab} />
			<Card>
				<CardBody>
					<Tabs 
						tabPosition='left' 
						type="card" 
						activeKey={currentTab} 
						onChange={key => setCurrentTab(key)}
						items={service.business_scope === 'inspeccion' ? inspectionItems : testItems}
					/>
				</CardBody>
			</Card>
			<ServiceProfileDetails service={service} />
		</React.Fragment>
	)
}