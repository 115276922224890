import React from 'react'
import { CSVLink } from "react-csv"
import { Button } from 'reactstrap'

export default function ExportTasksButton({ verificationList, taskTemplates, scopeFilter }) {

	const headers = [
		{ label: "item", key: "sku" },
		{ label: "articulo", key: "article" },
		{ label: "descripción específica", key: "legal_description" },
		{ label: "plazo", key: "description" },
		{ label: "resultado", key: "" },
		{ label: "observaciones", key: "" },
	];

	if(!taskTemplates) return null

	return (
		<React.Fragment>
			<CSVLink 
				data={taskTemplates} 
				headers={headers}
				filename={`Listado Verificación Pendiente - ${verificationList.service.proposal.inquiry.branch_spec.branch.instalation_type_name} - ${scopeFilter ? scopeFilter : 'superficiales y enterrados'} - ${verificationList.proposal_sku}.csv`}
			>
				<Button color='primary' outline>
					Exportar Listado
				</Button>
			</CSVLink>
		</React.Fragment>
	)
}