import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import BranchesTable from './partials/BranchesTable'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getBranches } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function BranchesList() {
	const { isConnected } = useSelector(state => state.offline)
	const [branches, setBranches] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!branches && isConnected && getBranches({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'instalationType,company'
		})
			.then(res => {
				setBranches(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [branches, filterType, filterValue, isConnected, pagination, filterScope])

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Directorio de Instalaciones"
				subtitle="Plataforma DEWAR"
				reload={() => setBranches(null)}
				options={[
					{ label: 'Buscar por nombre instalación', value: 'name' },
					{ label: 'Buscar por nombre empresa', value: 'company.name' },
					{ label: 'Buscar por documento empresa', value: 'company.document' },
					{ label: 'Buscar por tipo de instalación', value: 'instalationType.name' },
					{ label: 'Buscar por nombre propietario', value: 'branchSpecs.owner_name' },
					{ label: 'Buscar por nombre operador', value: 'branchSpecs.operator_name' },
					{ label: 'Buscar por departamento', value: 'address_state' },
				]}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				scopes={[
					{ label: 'Instalación con acceso al portal', value: 'accessGranted' },
					{ label: 'Instalación sin acceso al portal', value: 'accessUngranted' },
				]}
			/>
			<Card>
				<CardBody>
					<BranchesTable 
						branches={branches}
						reload={() => setBranches(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}