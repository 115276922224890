import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { faUsers, faFileSignature, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'

import DownloadReportModal from './partials/DownloadReportModal'
import InternetRequired from '../Offline/partials/InternetRequired'

import PageTitle from '../../components/PageTitle'
import MenuWidget from '../../components/MenuWidget'

export default function ReportsDashboard() {
	const { isConnected } = useSelector(state => state.offline)
	const [reportModal, setReportModal] = useState(null)

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<PageTitle title="Reportes Administradores" subtitle="Descarga de reportes Plataforma Dewar." />
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget 
						title="Reporte de Propuetas"
						subtitle="Informe de propuestas comerciales"
						icon={faMoneyBillWave}
						onClick={() => setReportModal({
							title: 'Reporte de Propuetas',
							slug: 'proposals',
							url: ''
						})}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Reportes de Certificados"
						subtitle="Informe de certificadores Dewar"
						icon={faFileSignature}
						onClick={() => setReportModal({
							title: 'Reportes de Certificados',
							slug: 'certificates',
							url: ''
						})}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Reporte de Inspectores"
						subtitle="Informe de inspectores Dewar"
						icon={faUsers}
						onClick={() => setReportModal({
							title: 'Reporte de Inspectores',
							slug: 'inspectors',
							url: ''
						})}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Exportar Directorio de Empresas"
						subtitle="Listado completo de empresas"
						icon={faUsers}
						onClick={() => setReportModal({
							title: 'Directorio de Empresas',
							slug: 'companies',
							url: ''
						})}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Cuentas de Cobro SOLDICOM"
						subtitle="Listado de cuentas de cobro con descuento"
						icon={faUsers}
						onClick={() => setReportModal({
							title: 'Cuentas de Cobro SOLDICOM',
							slug: 'soldicom',
							url: ''
						})}
					/>
				</Col>
			</Row>
			{ reportModal && (
				<DownloadReportModal 
					visible
					onClose={() => setReportModal(null)}
					data={reportModal}
				/>
			)}
		</React.Fragment>
	)
}