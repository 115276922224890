import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {store, persistor} from './store'

import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css'
import './assets/css/helpers.css'
import './assets/css/main.css'

Bugsnag.start({
	apiKey: '561c42b0d9ce8432fddf0dbeb6f30453',
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

const app = (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<HashRouter>
				<ErrorBoundary>
					<App />
				</ErrorBoundary>
			</HashRouter>
		</PersistGate>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorkerRegistration.register();
