import {
	SYNC_TASK_TEMPLATES
} from './actions'
import moment from 'moment'

const INITIAL_STATE = {
	data: [],
	sync_at: null
}

const taskTemplatesReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
		case SYNC_TASK_TEMPLATES:
			return { 
				...state,
				data: action.payload,
				sync_at: moment().format('DD/MMM/YY h:mm A')
			}

      default:
         return { ...state }
   }
}

export default taskTemplatesReducer