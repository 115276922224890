import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Empty, Table, Tooltip, Popconfirm, message, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditIslandSpecModal from './EditIslandSpecModal'

import { offlineUpdateReview } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'
import { deleteReviewSpec } from '../services'
import { handleError } from '../../../helpers'

export default function IslandSpecsTable({ islandSpecs, reload, editable, serviceId, serviceReview }) {
	const { isConnected } = useSelector(state => state.offline)
	const [showEditModal, setShowEditModal] = useState(null)
	const dispatch = useDispatch()

	const columns = [
		{
			title: 'No Isla',
			dataIndex: 'island_number'
		},
		{
			title: 'No. Equipos de suministro',
			dataIndex: 'equipos_suministro_total',
		},
		{
			title: 'No. Mangueras',
			dataIndex: 'mangueras_total'
		},
		{
			title: 'Acciones',
			render: (t,r) => editable && (
				<React.Fragment>
					{ r.id ? (
						<React.Fragment>
							<Tooltip title="Editar" onClick={() => setShowEditModal(r)}>
								<FontAwesomeIcon className='text-link text-primary' icon={faEdit} />
							</Tooltip>
							<Divider type='vertical' />
							<Tooltip title="Eliminar">
								<Popconfirm
									title="Esta acción no se puede revertir"
									okText="Eliminar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => isConnected ? handleDelete(r.id) : handleDeleteOffline(r.id)}
								>
									<FontAwesomeIcon className='text-link text-danger' icon={faTrash} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					) : <em>Una vez sincronize podra editar o eliminar</em>}
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteReviewSpec(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleDeleteOffline = id => {
		dispatch(offlineUpdateReview({
			...serviceReview,
			island_specs: serviceReview.island_specs.filter(r => r.id !== parseInt(id))
		}, serviceId))
		dispatch(queueRequest({ method: 'DELETE', url: `/review_specs/${id}` }))
		message.info('Actualización en espera de internet')
		reload()
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={islandSpecs}
				columns={columns}
				size='small'
				loading={!islandSpecs}
				locale={{ emptyText: <Empty description="No tiene islas/equipos asociados al acta" imageStyle={{ height: '70px' }} /> }}
				pagination={false}
				rowKey={r => r.id ? r.id : Math.random()}
			/>
			{ showEditModal && (
				<EditIslandSpecModal
					visible
					onClose={() => setShowEditModal(null)}
					islandSpec={showEditModal}
					reload={reload}
					serviceId={serviceId}
					serviceReview={serviceReview}
				/>
			)}
		</React.Fragment>
	)
}