import React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Upload } from "antd"
import AWS from "aws-sdk"

const { Dragger } = Upload

export default function UploadAcceptanceAttachment({ setAttachmentUri, proposalId }) {

	const props = {
		multiple: false,
		customRequest({
			action,
			data,
			file,
			filename,
			headers,
			onError,
			onProgress,
			onSuccess,
			withCredentials
		}) {
			AWS.config.update({
				accessKeyId: "AKIA4NB262CULJ4WQ2HK",
    			secretAccessKey: "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"
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'dewar' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop().toLowerCase();
		
			const objParams = {
				Bucket: "dewar",
				Key: "app/proposals/attachments/" + proposalId + '.' + fileExt,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);						
						setAttachmentUri(`https://dewar.s3.us-east-1.amazonaws.com/app/proposals/attachments/${proposalId}.${fileExt}`)
					}
				});
			}
		};

	return (
		<React.Fragment>
			<Dragger {...props}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">Haga clic o arraste el soporte de aceptación</p>
				<p className="ant-upload-hint">
					Solo se permite adjuntar un archivo menor a 5MB
				</p>
			</Dragger>
		</React.Fragment>
	)
}