import React, { useState } from 'react'
import { Empty, Tooltip, Popconfirm, Table, message } from 'antd'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import TicketNotesModal from '../../../TicketNotes/partials/TicketNotesModal'

import { updateTicket } from '../../../Tickets/services'
import { handleError } from '../../../../helpers'

export default function CertificateTicketsTable({ tickets, reload }) {
	const [ticketNotesModal, setTicketNotesModal] = useState(null)

	const onToggleResolved = (ticket) => {
		updateTicket(ticket.id, {
			resolved_at: ticket.resolved_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{ 
			title: 'Fecha de envío',
			dataIndex: 'created_at',
			render: t => `${moment(t).format('YYYY-MM-DD')} (${moment(t).fromNow()})`
		},
		{
			title: 'Empresa',
			dataIndex: 'company',
			render: t => t.name
		},
		{
			title: 'Tipo de Solicitud',
			dataIndex: 'ticket_type',
			render: t => t.name
		},
		{
			title: 'Comentarios',
			dataIndex: 'ticket_notes',
			render: t => `${t.length} comentarios`
		},
		{
			title: 'Estado',
			dataIndex: 'resolved_at',
			render: (t,r) => (
				<Tooltip title={t ? 'Marcar como abierto' : 'Marcar como resuelto'}>
					<Popconfirm
						title={t ? '¿Marcar como abierto nuevamente?' : '¿Marcar como resuelto?'}
						cancelText="Cancelar"
						okText="Confirmar"
						onConfirm={() => onToggleResolved(r)}
					>
						<Badge color={t ? 'success' : 'info'} style={{ cursor: 'pointer' }}>
							{ t ? 'Resuelto' : 'En Revisión' }
						</Badge>
					</Popconfirm>
				</Tooltip>
			)
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Comentarios" className='text-link'>
						<FontAwesomeIcon
							icon={faComments} 
							onClick={() => setTicketNotesModal(r)}
						/>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = (r) => (
		<React.Fragment>
			<p className='mb-0'><strong>Correo para notificaciones: </strong>{r.notify_email}</p>
			<p className='mb-0'><strong>Detalle de la solicitud: </strong>{r.observations}</p>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table
				dataSource={tickets}
				columns={columns}
				rowKey={r => r.id}
				loading={!tickets}
				locale={{ emptyText: <Empty description="No se han recibido solicitudes de modificación" /> }}
				expandable={{ expandedRowRender }}
				size="small"
			/>
			{ ticketNotesModal && (
				<TicketNotesModal
					visible
					onClose={() => setTicketNotesModal(null)}
					ticket={ticketNotesModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}