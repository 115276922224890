import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import RetryNoticePreview from './RetryNoticePreview'

export default function RetryNotices() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/retry_notices/preview/:id" component={RetryNoticePreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}