import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import InquiryDocument from './partials/InquiryDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getPublicInquiry } from './services'
import { handleError } from '../../helpers'

export default function InquiryPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [inquiry, setInquiry] = useState(null)

	useEffect(() => {
		!inquiry && isConnected && getPublicInquiry(id, {
			include: 'branchSpec,employee,proposals,branchSpec.branch.company,branchSpec.branch.instalationType,branchSpec.tanks'
		})	
			.then(res => setInquiry(res.data.data))
			.catch(error => handleError(error))
	}, [ inquiry, id, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!inquiry) return <Spinner />

	return (
		<Container>
			<InquiryDocument inquiry={inquiry} />
		</Container>
	)
}

const Container = styled.div`
	max-width: 1200px;
	margin: auto;
`