import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import EmployeeForm from './EmployeeForm'

import { updateEmployee } from '../services'
import { handleError } from '../../../helpers'

export default function EditEmployeeModal({ visible, onClose, employee, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		updateEmployee(employee.id, values)
			.then(res => {
				message.success(res.data.message)
				reset()
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Personal Vinculado</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<EmployeeForm
							employee={employee} 
							register={register}
							errors={errors}
						/>
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}