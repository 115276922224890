import React from 'react'

import SERVICE_REVIEW_TEMPLATE_INS01 from './templates/SERVICE_REVIEW_TEMPLATE_INS01';
import SERVICE_REVIEW_TEMPLATE_PRU01 from './templates/SERVICE_REVIEW_TEMPLATE_PRU01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function ServiceReviewDocument({ serviceReview, serviceData = null }) {
	
	switch (serviceReview.format_version.format_template) {
		case 'SERVICE_REVIEW_TEMPLATE_INS01':
			return <SERVICE_REVIEW_TEMPLATE_INS01 data={serviceReview} serviceData={serviceData} />

		case 'SERVICE_REVIEW_TEMPLATE_PRU01':
			return <SERVICE_REVIEW_TEMPLATE_PRU01 data={serviceReview} serviceData={serviceData} />
	
		default:
			return <DocumentTemplate404 />
	}

}