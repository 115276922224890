import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import FormatVersionSelect from '../../../FormatVersions/FormatVersionSelect'

export default function NewReviewLogbookForm({ register, errors, watch, setValue, onlyEditable }) {
    const formatKeyword = watch('format_version_keyword')

    useEffect(() => {
        if(formatKeyword){
            if(formatKeyword.includes('tank')) setValue('spec_type', 'tank')
            else setValue('spec_type', 'pipeline')
            setValue('spec_category_slug', '')
        }
    }, [ formatKeyword, setValue ])

    return (
        <React.Fragment>
            { !onlyEditable && (
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Versión del Formato <span className='text-danger'>*</span></Form.Label>
                        <FormatVersionSelect
                            keyword={`reviewLogbook`}
                            setValue={setValue}
                            businessScope="pruebas"
                        />
                        { errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
                        <Form.Control {...register('expire_at')} disabled />
                    </Form.Group>
                </Row>
            )}
            <Row>
                { !onlyEditable && (
                    <Form.Group as={Col}>
                        <Form.Label>Bitacora de Inspección para <span className='text-danger'>*</span></Form.Label>
                        <Form.Control
                            as="select"
                            {...register('spec_type', { required: true })}
                            disabled
                        >
                            <option value="tank">Tanque</option>
                            <option value="pipeline">Tubería</option>
                        </Form.Control>
                        { errors.spec_type && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                    </Form.Group>
                )}
                <Form.Group as={Col}>
                    <Form.Label>Tipo <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        as="select"
                        {...register('spec_category_slug', { required:true })}
                    >
                        { watch('spec_type') === 'tank' ? (
                            <React.Fragment>
                                <option value="">:: Seleciona una opción ::</option>
                                <option value="subterraneo">Subterraneo</option>
                                <option value="superficial">Superficial</option>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <option value="">:: Seleciona una opción ::</option>
                                <option value="dispensadores">Dispensadores</option>
                                <option value="surtidores">Surtidores</option>
                                <option value="meter">Meter</option>
                            </React.Fragment>
                        )}
                    </Form.Control>
                    { errors.spec_category_slug && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
            </Row>
            <Form.Group>
                <Form.Label>Fecha de Inspección <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    type="date"
                    {...register('register_at', { required:true })}
                />
                { errors.register_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Número <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('spec_number', { required:true })}
                        placeholder='Escriba aquí...'
                    />
                    { errors.spec_number && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Producto <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        as="select"
                        {...register('product_name', { required:true })}
                    >
                        <option value="">:: Seleccionar una opción ::</option>
                        <option>Corriente</option>
                        <option>Extra</option>
                        <option>Diesel</option>
                        <option>ACPM</option>
                        <option>Otro</option>
                    </Form.Control>
                    { errors.product_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
            </Row>
            { watch('product_name') === 'Otro' && (
                <Form.Group as={Col}>
                    <Form.Label>Específique cual otro producto <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register('product_other_name', { required:true })}
                        placeholder='Escriba aquí...'
                    />
                    { errors.product_other_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
            )}
        </React.Fragment>
    )
}