import dewarApi, { getDomain } from '../../services/dewarApi'

export const getFormatVersions = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/format_versions', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getFormatVersion = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/format_versions/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeFormatVersion = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/format_versions', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateFormatVersion = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/format_versions/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteFormatVersion = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/format_versions/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchFormatVersions = async (name, token) => (
	fetch( getDomain()+`/format_versions?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(format_version => ({
				label: format_version.name,
				value: format_version.id,
			})),
		)
)

export const restoreFormatVersion = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/format_versions/restore/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)