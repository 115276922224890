import dewarApi, { getDomain } from '../../services/dewarApi'

export const getTaskTemplates = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/task_templates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getTaskTemplate = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/task_templates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTaskTemplate = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/task_templates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTaskTemplate = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/task_templates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTaskTemplate = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/task_templates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchTaskTemplates = async (name, token) => (
	fetch( getDomain()+`/task_templates?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(task_template => ({
				label: task_template.name,
				value: task_template.id,
			})),
		)
)

export const attachInstalationType = (id, instalationTypeId, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/task_templates/${id}/instalation_types/${instalationTypeId}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const detachInstalationType = (id, instalationTypeId) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/task_templates/${id}/instalation_types/${instalationTypeId}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const toggleEvidenceTemplate = (id, evidenceTemplateId) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/task_templates/${id}/evidence_templates/${evidenceTemplateId}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)