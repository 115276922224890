import React from 'react'

import ACCEPTANCE_TEMPLATE_INS01 from './templates/ACCEPTANCE_TEMPLATE_INS01';
import ACCEPTANCE_TEMPLATE_PRU01 from './templates/ACCEPTANCE_TEMPLATE_PRU01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function AcceptanceDocument({ proposal, hideHeader = false }) {
	
	switch (proposal.format_version.format_template) {
		case 'PROPOSAL_TEMPLATE_INS01':
			return <ACCEPTANCE_TEMPLATE_INS01 data={proposal} hideHeader={hideHeader} />

		case 'PROPOSAL_TEMPLATE_PRU01':
			return <ACCEPTANCE_TEMPLATE_PRU01 data={proposal} hideHeader={hideHeader} />
	
		default:
			return <DocumentTemplate404 />
	}

}