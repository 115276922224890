import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'

import MainMenu from './partials/MainMenu'
import NetworkStatusWidget from '../containers/Offline/partials/NetworkStatusWidget'

import Logo from '../assets/images/logo.png'

export default function MainLayout({ children }) {
	const [openMenu, setOpenMenu] = useState(false)
	const history = useHistory()

	return (
		<Container>
			<TopBar className="no-print">
				<LogoContainer>
					<img src={Logo} alt="Logo Dewar" width="130px" />
				</LogoContainer>
				<FontAwesomeIcon 
					icon={ openMenu ? faTimes : faBars}
					onClick={()=>setOpenMenu(!openMenu)}
					className="nav-toggle-icon mr-20"
				/>
				<FontAwesomeIcon 
					icon={faArrowLeft}
					onClick={()=>history.goBack()}
					className="nav-toggle-icon"
				/>
				<div className="float-right mt-10 hide-sm">
					<NetworkStatusWidget />
				</div>
			</TopBar>
			{ openMenu && (
				<MainMenu closeMenu={()=>setOpenMenu(false)} />
			)}
			<MainContent className='main-content'>
				{children}
			</MainContent>
			<Footer className="no-print">
				<p className='small'>
					Desarrollado por{" "}
					<a href="//smart4.com.co" target="_blank" rel="noreferrer">
						Smart4 Solutions
					</a>
					{" | "}
					Versión {process.env.REACT_APP_VERSION}
				</p>
			</Footer>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #f7f8f9;	
`

const TopBar = styled.div`
	width: 100%;
	padding: 5px 30px 0px;
	background-color: #e78035;
	color: #fff;
	position: fixed;
	top: 0px;
	z-index: 10;
`

const MainContent = styled.div`
	padding: 30px;
	width: 100%;
	min-height: 90vh;
	position:relative;
	top: 45px;
`

const Footer = styled.div`
	padding: 30px 20px 20px;
	width: 100%;
	text-align: center;
`

const LogoContainer = styled.div`
	background-color: white;
	display: inline-block;
	margin-right: 20px;
	border-radius: 100px;
	padding: 0px 7px;
	margin-bottom: 5px;
`