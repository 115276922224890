import React from 'react'
import { Form } from 'react-bootstrap'

export default function PlanTaskForm({ register, errors, planTask }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Día <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('day', { required: true })}
					defaultValue={planTask && planTask.day.toString()}
				>
					<option value="1">Primero</option>
					<option value="2">Segundo</option>
					<option value="3">Tercero</option>
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Hora <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('time', { required: true })}
					defaultValue={planTask && planTask.time}
					type="time"
				/>
				{ errors.time && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Requisito / Actividad <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					defaultValue={planTask && planTask.name}
					as="textarea"
					style={{ height: '70px' }}
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}