import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

import ReviewLogbookDocument from './partials/ReviewLogbookDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetReviewLogbook } from './services'
import { handleError } from '../../helpers'

export default function ReviewLogbookPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [reviewLogbook, setReviewLogbook] = useState(null)

	useEffect(() => {
		!reviewLogbook && isConnected && publicGetReviewLogbook(id, {
			include: 'formatVersion,serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch'
		})
			.then(res => setReviewLogbook(res.data.data))
			.catch(error => handleError(error))
	}, [reviewLogbook, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!reviewLogbook) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ReviewLogbookDocument reviewLogbook={reviewLogbook} />
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`