import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import TicketsList from './TicketsList'

export default function Tickets() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/tickets" component={TicketsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}