import React from 'react'

import PRE_INVOICE_TEMPLATE_INS01 from './templates/PRE_INVOICE_TEMPLATE_INS01';
import PRE_INVOICE_TEMPLATE_PRU01 from './templates/PRE_INVOICE_TEMPLATE_PRU01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function PreInvoiceDocument({ preInvoice }) {
	
	switch (preInvoice.format_version.format_template) {
		case 'PRE_INVOICE_TEMPLATE_INS01':
			return <PRE_INVOICE_TEMPLATE_INS01 data={preInvoice} />

		case 'PRE_INVOICE_TEMPLATE_PRU01':
			return <PRE_INVOICE_TEMPLATE_PRU01 data={preInvoice} />
	
		default:
			return <DocumentTemplate404 />
	}

}