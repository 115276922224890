import React, { useState } from 'react'
import { Result } from 'antd'
import { useDispatch } from 'react-redux'

import { checkConnection } from '../actions'
import { Button, Spinner } from 'reactstrap'

export default function InternetRequired() {
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const onRefresh = () => {
		setLoading(true)
		setTimeout(() => {
			dispatch(checkConnection())
			setLoading(false)
		}, 700)
	}

	return (
		<React.Fragment>
			<Result 
				status="500" 
				title="Este módulo requiere de conexión a internet"
				extra={(
					<Button 
						color="primary" 
						onClick={onRefresh}
						disabled={loading}
						outline
					>
						Verificar de nuevo
						{ loading && <Spinner size="sm" style={{ width:'15px', height:'15px', marginLeft:'5px' }} /> }
					</Button>
				)}
			/>
		</React.Fragment>
	)
}