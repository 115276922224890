import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { updateBranch } from '../services'
import { handleError } from '../../../helpers'

export default function BranchPortalAccessModal({ visible, onClose, reload, branch }) {
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors }, watch } = useForm({
		defaultValues: {
			portal_is_active: branch.portal_is_active ? 1 : 0,
			portal_username: branch.portal_username ? branch.portal_username : '',
		}
	})

	const onSubmit = values => {
		setLoading(true)
		if(branch.portal_username === values.portal_username) 
			delete values.portal_username
			
		updateBranch(branch.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible}>
			<ModalHeader toggle={onClose}>Acceso a Portal de Clientes</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label>Acceso al Portal de Clientes</Form.Label>
						<Form.Control
							as="select"
							{...register('portal_is_active', { required: true })}
						>
							<option value="">:: Seleccione una opción ::</option>
							<option value={1}>Con acceso al portal</option>
							<option value={0}>Sin acceso al portal</option>
						</Form.Control>
						{ errors.portal_is_active && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
					</Form.Group>
					{ parseInt(watch('portal_is_active')) === 1 && (
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Usuario <span className="text-danger">*</span></Form.Label>
								<Form.Control {...register('portal_username', {required:true})} />
								{ errors.portal_username && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Contraseña <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									{...register('portal_password', {required:true})} 
									type="password"
								/>
								{ errors.portal_password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
							</Form.Group>
						</Row>
					)}
					<Button color='primary' disabled={loading}>
						Actualizar acceso al portal
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}