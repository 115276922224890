import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'

export default function SERVICE_PLAN_TEMPLATE_PRU01({ data }) {

    const branch = data.service_inspector.service.proposal.inquiry.branch_spec.branch

    return (
        <Container>
            <DocumentHeader
				title="PLAN DE INSPECCIÓN"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
			/>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td className='bg-light bold'>Nombre de la Instalación</td>
                        <td colSpan={3}>{branch.name}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Representante Legal:</td>
                        <td colSpan={3}>{branch.company.legal_owner_name}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Nombre de quien atenderá la Inspección</td>
                        <td>{data.service_inspector.service.proposal.inquiry.employee.name}</td>
                        <td className='bg-light bold'>Cargo</td>
                        <td>{data.service_inspector.service.proposal.inquiry.employee.position}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Correo electrónico</td>
                        <td colSpan={3}>{data.service_inspector.service.proposal.inquiry.employee.email}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Dirección</td>
                        <td>{branch.address_line} - {branch.address_state}, {branch.address_city}</td>
                        <td className='bg-light bold'>Teléfono/celular</td>
                        <td>{branch.mobile} {branch.phone}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders mb-3'>
                <tbody>
                    <tr>
                        <td className='bg-light bold' width="260px">Cantidad de Tanques:</td>
                        <td>{data.service_inspector.test_details.total_tanks} tanques</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td className='bg-light bold'>Criterio:</td>
                        <td>{data.service_inspector.service.criteria}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td colSpan={2} className='bg-light bold'>INSPECTOR ASIGNADO</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Nombre</td>
                        <td>{data.service_inspector.user.name}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td className='bg-light bold'>El inspector asignado es el Representante de DEWAR para la realización de la prueba de hermeticidad, el Director Técnico revisa los resultados y toma decisión sobre la declaración de la conformidad en los tiempos establecidos por el procedimiento. Documentos de interés como el Reglamento de Inspección y Procedimiento de quejas y/o apelaciones, se encuentran disponibles bajo solicitud.</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td width="160px" className='bg-light bold'>Fecha de la inspección *</td>
                        <td>{moment(data.inspected_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Lugar:</td>
                        <td>{data.location}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Hora*</td>
                        <td className='bg-light bold'>Requisito / Actividad por evaluar</td>
                    </tr>
                    { data.plan_tasks.map(planTask => (
                        <tr key={planTask.id}>
                            <td>{planTask.time}</td>
                            <td>{planTask.name}</td>
                        </tr>
                    )) }
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td className='bg-light bold'>Observaciones:</td>
                    </tr>
                    <tr>
                        <td style={{ whiteSpace:'pre-line' }}>{data.observations}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table all_borders'>
                <tbody>
                    <tr>
                        <td className='bg-light bold'>Fecha envío del plan</td>
                        <td>{moment(data.sent_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                    </tr>
                </tbody>
            </table>
            <div className='text-center mt-10 small'>
            Carrera 61 # 96 A 23 Oficina 201 Barrio Andes, Bogotá – Colombia. Cel. 3163702077<br />
            E-mail. comercial@dewar.com.co  Web. www.dewar.com.co
            </div>
        </Container>
    )
}

const Container = styled.div`
    font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	min-height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 5px 3px;
	}
    background-color: #fff;
    span.variable {
        color: #e78035;
        font-weight: 600;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
`