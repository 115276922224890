import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faImages, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditInstalationTypeModal from './EditInstalationTypeModal'
import ReportDefaultEvidencesModal from '../../ReportDefaultEvidence/ReportDefaultEvidenceModal'

import { deleteInstalationType } from '../services'
import { handleError } from '../../../helpers'

export default function InstalationTypesTable({ instalationTypes, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editInstalationType, setEditInstalationType] = useState(null)
	const [reportDefaultEvidence, setReportDefaultEvidence] = useState(null)

	const handleDelete = id => {
		deleteInstalationType(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Criterio',
			dataIndex: 'criteria',
			render: t => t ? t : <em className='hint-text'>Sin descripción</em>
		},
		{
			title: 'Acciones',
			width: '150px',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<div className='inline' onClick={()=>setEditInstalationType(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Evidencia por Defecto">
						<div className='inline text-link' onClick={()=>setReportDefaultEvidence(record)}>
							<FontAwesomeIcon icon={faImages} />
						</div>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el tipo de instalación?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = record => (
		<React.Fragment>
			<p className='mb-0'><strong>Descripción: </strong>{record.hint}</p>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table 
				dataSource={instalationTypes}
				columns={columns}
				rowKey={record => record.id}
				expandable={{ expandedRowRender }}
			/>
			{ editInstalationType && (
				<EditInstalationTypeModal 
					visible
					onClose={() => setEditInstalationType(null)}
					instalationType={editInstalationType}
					reload={reload}
				/>
			)}
			{ reportDefaultEvidence && (
				<ReportDefaultEvidencesModal 
					visible
					onClose={() => setReportDefaultEvidence(null)}
					instalationType={reportDefaultEvidence}
				/>
			)}
		</React.Fragment>
	)
}