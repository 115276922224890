import React from 'react'
import QRCode from 'react-qr-code'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'

import logo from '../../../../assets/images/logo_dewar_onac.png'

export default function CERTIFICATE_TEMPLATE_PRU01({ data, filters }) {
	const serviceReview = data.service_veredict.service_report.service_review
	const serviceInspector = serviceReview.service_plan.service_inspector
	const branch = serviceInspector.service.proposal.inquiry.branch_spec.branch

	const tanks = serviceReview.review_logbooks.filter(r => (
		r.spec_type === 'tank'
		&& r.spec_details.identificacion_tanque
		&& r.requirements_survey.is_director_compliant === 'Conforme'
	))

	const pipelines = serviceReview.review_logbooks.filter(r => (
		r.spec_type === 'pipeline'
		&& r.spec_details.desde_tanque
		&& r.requirements_survey.is_director_compliant === 'Conforme'
	))

	const totalPages = (tanks.length < 5 || pipelines.length < 5) ? 1 : ( (tanks.length < 15 || pipelines.length < 15) ? 2 : ( (tanks.length < 25 || pipelines.length < 25) ? 3 : ( (tanks.length < 35 || pipelines.length < 35) ? 4 : 5)) )

	return (
		<React.Fragment>
			<Document 
				className='certificateContainer' 
				hideBackground={filters.hideBackground}
				isOfficial={filters.isOfficial}
			>
				<div className='clearfix mb-20' />
				<div className='text-center'>
                    <DocumentHeader 
						filters={filters}
						data={data}
						branch={branch}
					/>
					<p className='mb-7'>Descripción de los tanques inspeccionados declarados conformes:</p>
					<div className='main_table'>
						{ tanks.length > 0 && (
							<table className='full-width border text-center table-small'>
								<tbody>
									<tr className='bold'>
										<th className='border bg-light'>Identificación del Tanque</th>
										<th className='border bg-light'>Capacidad (gal)</th>
										<th className='border bg-light'>Producto</th>
										<th className='border bg-light'>Presión de la prueba (PSI)</th>
									</tr>
									{ tanks.map((tank, i) => i < 4 && (
										<tr key={i}>
											<td className='border'>
												{tank.spec_details.identificacion_tanque}
											</td>
											<td className='border'>
												{tank.spec_details.capacidad}
											</td>
											<td className='border'>
												{tank.product_name} {tank.product_other_name}
											</td>
											<td className='border'>
												{tank.tightness_tests.test1_presion}
											</td>
										</tr>
									)) }
									{ tanks.length > 4 && (
										<tr>
											<td colSpan={3}>Nota: Otros tanques ver anexos</td>
										</tr>
									)}
								</tbody>
							</table>
						)}
					</div>
					<div className='main_table'>
						{ pipelines.length > 0 && (
							<table className='full-width border text-center table-small'>
								<tbody>
									<tr className='bold'>
										<th className='border bg-light'>Desde el Tanque</th>
										<th className='border bg-light'>Hacia</th>
										<th className='border bg-light'>Producto</th>
										<th className='border bg-light'>Presión de la prueba (PSI)</th>
									</tr>
									{ pipelines.map((pipeline, i) => i < 4 && (
										<tr key={i}>
											<td className='border'>
												{pipeline.spec_details.desde_tanque}
											</td>
											<td className='border'>
												{pipeline.spec_details.hacia}
											</td>
											<td className='border'>
												{pipeline.product_name} {pipeline.product_other_name}
											</td>
											<td className='border'>
												{pipeline.tightness_tests.test1_presion}
											</td>
										</tr>
									)) }
									{ pipelines.length > 4 && (
										<tr>
											<td colSpan={3}>Nota: Otros tanques ver anexos</td>
										</tr>
									)}
								</tbody>
							</table>
						)}
					</div>

					<div className='details_container text-left mt-2 mb-5'>
						<p className='small'>Observaciones: {data.observations ? data.observations : <em>Sin observaciones</em>}</p>
					</div>

					<p className='text-center mb-5'>Informe de Soporte: {serviceInspector.sku}</p>

					<DocumentFooter 
						filters={filters} 
						data={data} 
						page={1} 
						pageTotal={totalPages}
					>
						<div className='text-center mb-10'>
							<p style={{ fontSize:'13px' }} className='bold mb-5'>Edición {data.modifications_count ? parseInt(data.modifications_count) + 1 : 1} – Emisión del Certificado. {data.format_version.sku}</p>
							<p className='xs'>En el certificado de inspección se encuentran los tanques y tuberías que el día de la inspección pasaron las pruebas de hermeticidad y se declararon conformes de acuerdo con el Reglamento técnico. En cumplimiento de la Resolución 40198 de 2021 numeral 5.6.2 se deberán realizar estas pruebas mínimo una vez cada 2 años.  Para verificar validez del certificado comunicarse Celular 3163702077; pruebas@dewar.com.co</p>						
						</div>
					</DocumentFooter>
				</div>
			</Document>
			{ (tanks.length >= 5 || pipelines.length >= 5) && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraReviewLogbooks 
						filters={filters}
						data={data}
						branch={branch}
						serviceReview={serviceReview}
						tanks={tanks}
						pipelines={pipelines}
						page={2}
						pageTotal={totalPages}
					/>
				</Document>
			)}
			{ (tanks.length >= 15 || pipelines.length >= 15) && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraReviewLogbooks 
						filters={filters}
						data={data}
						branch={branch}
						tanks={tanks}
						pipelines={pipelines}
						page={3}
						pageTotal={totalPages}
					/>
				</Document>
			)}
			{ (tanks.length >= 25 || pipelines.length >= 25) && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraReviewLogbooks 
						filters={filters}
						data={data}
						branch={branch}
						tanks={tanks}
						pipelines={pipelines}
						page={4}
						pageTotal={totalPages}
					/>
				</Document>
			)}
		</React.Fragment>
	)
}

const DocumentExtraReviewLogbooks = ({ filters, data, branch, tanks, pipelines, page, pageTotal }) => (
	<React.Fragment>
		<div className='clearfix mb-20' />
		<div className='text-center full-width'>
			<DocumentHeader 
				filters={filters}
				data={data}
				branch={branch}
			/>
			<h5 className='mb-10'>ANEXO {page-1}</h5>
			<p className='mb-7'>Descripción de los tanques inspeccionados declarados conformes:</p>
			{ (tanks.length > (page-1)*5) && (
				<table className='full-width border text-center table-small mb-2'>
					<tbody>
						<tr className='bold'>
							<th className='border bg-light'>Identificación del Tanque</th>
							<th className='border bg-light'>Capacidad (gal)</th>
							<th className='border bg-light'>Producto</th>
							<th className='border bg-light'>Presión de la prueba (PSI)</th>
						</tr>
						{ tanks.map((tank, i) => {
							let startIndex = page === 2 ? 5 : (page === 3 ? 15 : (page === 4 ? 25 : 35))
							let endIndex = page === 2 ? 16 : (page === 3 ? 26 : (page === 4 ? 36 : 46))
							if(i >= startIndex && i <= endIndex){
								return (
									<tr key={tank.id}>
										<td className='border'>
											{tank.spec_details.identificacion_tanque}
										</td>
										<td className='border'>
											{tank.spec_details.capacidad}
										</td>
										<td className='border'>
											{tank.product_name} {tank.product_other_name}
										</td>
										<td className='border'>
											{tank.tightness_tests.test1_presion}
										</td>
									</tr>
								)
							} else {
								return null
							}
						}) }
					</tbody>
				</table>
			)}
			{ (pipelines.length > (page-1)*5) && (
				<table className='full-width border text-center table-small mt-20 mb-2'>
					<tbody>
						<tr className='bold'>
							<th className='border bg-light'>Desde Tanque</th>
							<th className='border bg-light'>Hacia</th>
							<th className='border bg-light'>Producto</th>
							<th className='border bg-light'>Presión de la prueba (PSI)</th>
						</tr>
						{ pipelines.map((pipeline, i) => {
							let startIndex = page === 2 ? 5 : (page === 3 ? 15 : (page === 4 ? 25 : 35))
							let endIndex = page === 2 ? 16 : (page === 3 ? 26 : (page === 4 ? 36 : 46))
							if(i >= startIndex && i <= endIndex){
								return (
									<tr key={pipeline.id}>
										<td className='border'>
											{pipeline.spec_details.desde_tanque}
										</td>
										<td className='border'>
											{pipeline.spec_details.hacia}
										</td>
										<td className='border'>
											{pipeline.product_name} {pipeline.product_other_name}
										</td>
										<td className='border'>
											{pipeline.tightness_tests.test1_presion}
										</td>
									</tr>
								)
							} else {
								return null
							}
						}) }
					</tbody>
				</table>
			)}

			<DocumentFooter filters={filters} data={data} page={page} pageTotal={pageTotal} />
		</div>
	</React.Fragment>
)

const DocumentHeader = ({ filters, data, branch }) => (
	<React.Fragment>
		{ filters.isOfficial && !filters.hideBackground && (
			<div className='logoDiv'>
				<img src={logo} alt="Logo Dewar y ONAC" className='logoDewarOnac' />
			</div>
		)}
		<h1>DEWAR SAS</h1>
		<p className='sm-width small mb-7'>En DEWAR SAS contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 21-OIN-008, bajo la norma internacional ISO/IEC 17020:2012</p>
		<h5 className='mb-7'>Como Organismo de Inspección concede a:</h5>
		<h5 className='bold'>{data.company_name.toUpperCase()}</h5>
		<h5 className='mb-7'>NIT. {data.company_document}</h5>
		<h5 className='bold'>{branch.name.toUpperCase()}</h5>
		<p className='mb-7'>{branch.address_line}; {branch.address_city}, {branch.address_state}</p>
		<h5 className='bold'>CERTIFICADO DE INSPECCIÓN</h5>
		<p className='sm-width mb-7'>Pruebas de Hermeticidad</p>
		<h5 className='mb-7 bold'>{data.sku_formated}</h5>

		<p className='mb-10'>El cual se otorga en cumplimiento de los requisitos establecidos en {data.article_numerals}</p>		
	</React.Fragment>
)

const DocumentFooter = ({ filters, data, page, pageTotal, children }) => {
	const serviceReview = data.service_veredict.service_report.service_review

	const reviewLookbooksDates = () => {
		let dates = serviceReview.review_logbooks.map(r => r.register_at)

		return dates.filter((item, i, ar) => ar.indexOf(item) === i).join(', ')
	}
	
	return (
		<div className='document-footer'>
			<Row className='text-left mb-10'>
				<Col xs="3">
					{ filters.isOfficial && (
						<React.Fragment>
							<img src={`/images/firma_${data.service_veredict.service_report.director_user_id}.jpg`} alt="firma" className='img-firma' />
							<p className='bold'>{data.service_veredict.service_report.director_name}</p>
							<p className='bold'>Dirección Técnica</p>
							<p className='bold'>DEWAR – OIN</p>
						</React.Fragment>
					)}
				</Col>
				<Col className='text-center pt-20' xs="3">
					{ filters.isOfficial && (
						<a href={`https://admin.dewar.com.co/#/vista_previa/certificados/${data.id}`} target="_blank" rel="noreferrer">
							<QRCode
								style={{ height: "80px", maxWidth: "80px", width: "80px" }}
								value={`https://admin.dewar.com.co/#/vista_previa/certificados/${data.id}`}
							/>
						</a>
					)}
				</Col>
				<Col xs="6" className='text-right pt-20'>
					<p style={{ fontSize: '15px', marginBottom: '5px' }}>
						<strong>FECHA DE EMISIÓN:</strong> {moment(data.issued_at).format('DD-MMM-YYYY')}
					</p>
					<p><strong>Fecha de inspección:</strong> {reviewLookbooksDates()}</p>
					{ data.modified_at && <p><strong>Fecha de modificación:</strong> {moment(data.modified_at).format('DD-MMM-YYYY')}</p> }
				</Col>
			</Row>
			
			{ children }

			<div className='mb-5 mt-5'>
				<p className='xs'>Página {page}/{pageTotal}</p>
			</div>
			<div className='text-center'>
				<p className='mb-0 small bold'>Carrera 61 # 96 A 23 Oficina 201 Barrio Andes, Bogotá – Colombia. Cel. 3163702077. <br />E-mail. organismo@dewar.com.co</p>
			</div>
		</div>
	)
}

const Document = styled.div`
	width: 216mm;
	min-height: 279mm;
	padding: 0.75in 0.75in 0 0.75in;
	background-image: ${props => !props.hideBackground ? ( props.isOfficial ? `url('/images/fondo_de_agua.jpg')` : `url('/images/fondo_de_agua_borrador.jpeg')` ) : ''};
	background-size: cover;
	background-repeat-x: no-repeat;
	background-color: #fff;
	display: flex;
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 21px;
		margin-bottom: 0px;
		font-weight: bold
	}
	h5 {
		font-size: 16px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 13px;
		height: 15px;
	}
	.details_container {
		padding: 5px;
		border: 1px solid #888;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 7px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	td.border {
		padding: 0px;
  	}
	svg {
		vertical-align: unset;
	}
	.additional_tanks {
		height: 205mm;
		margin: 35px 0px;
	}
	.bg-light {
		background-color: #f2f2f2;
		font-weight: bold;
	}
	.img-firma {
		width: 90px;
		height: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.main_table {
		min-height: 140px;
  	}
  	.logoDewarOnac {
		width: 7.7cm;
  	}
  	.logoDiv {
		width: 7.9cm;
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -50px;
		left: 420px;
		margin-bottom: -50px;
  	}
`