import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import VerificationListPreview from './VerificationListPreview'

export default function VerificationLists() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/verification_lists/preview/:id" component={VerificationListPreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}