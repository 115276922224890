import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'

import ServicePlanForm from './ServicePlanForm'

import { updateServicePlan } from '../services'
import { handleError } from '../../../helpers'

export default function EditServicePlanModal({ visible, onClose, servicePlan, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			inspected_at: moment(servicePlan.inspected_at).format('YYYY-MM-DD'),
			observations: servicePlan.observations,
		}
	})

	const onSubmit = values => {
		updateServicePlan(servicePlan.id, values)
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Plan de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServicePlanForm register={register} errors={errors} onlyEditable />
						<Button color="primary" type="submit">Actualizar Registro</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}