import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import DepartmentAndCityForm from '../../../components/DepartmentAndCityForm'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchInstalationTypes } from '../../InstalationTypes/services'

export default function BranchForm({ branch, register, errors, watch, selectedInstalationType, setSelectedInstalationType }) {
	return (
		<React.Fragment>
			{selectedInstalationType && (
				<Form.Group className="mb-10">
					<Form.Label>Tipo de Instalación <span className="text-danger">*</span></Form.Label>
					<ServerSideSelect 
						value={selectedInstalationType}
						placeholder="Seleccione un tipo"
						fetchOptions={searchInstalationTypes}
						onChange={value => setSelectedInstalationType(value)}
						className="full-width"
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Nombre de la Instalación <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', {required:true})} defaultValue={branch && branch.name} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Dirección Completa <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('address_line', {required:true})} defaultValue={branch && branch.address_line} />
				{ errors.address_line && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<DepartmentAndCityForm 
				register={register} 
				errors={errors} 
				watch={watch} 
				current={branch} 
			/>
			<Form.Group>
				<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
				<Form.Control {...register('email', {})} defaultValue={branch && branch.email} />
				{ errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico #2 <small>(opcional)</small></Form.Label>
				<Form.Control {...register('email_secundary', {})} defaultValue={branch && branch.email_secundary} />
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico #3 <small>(opcional)</small></Form.Label>
				<Form.Control {...register('email_terciary', {})} defaultValue={branch && branch.email_terciary} />
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Celular <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('mobile', {required:true})} defaultValue={branch && branch.mobile} />
					{ errors.mobile && <Form.Text className="text-warning">Ingrese únicamente digitos.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Teléfono Fijo <small>(opcional)</small></Form.Label>
					<Form.Control {...register('phone', {})} defaultValue={branch && branch.phone} />
					{ errors.phone && <Form.Text className="text-warning">Ingrese únicamente digitos.</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Bandera <small>(opcional)</small></Form.Label>
					<Form.Control {...register('flag', { })} defaultValue={branch && branch.flag} />
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Código SICOM <small>(opcional)</small></Form.Label>
					<Form.Control {...register('sicom_code', { })} defaultValue={branch && branch.sicom_code} />
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Nombre del Constructor <small>(opcional)</small></Form.Label>
				<Form.Control {...register('constructor_name', {})} defaultValue={branch && branch.constructor_name} />
			</Form.Group>
		</React.Fragment>
	)
}