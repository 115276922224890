export const CHECK_CONNECTION = "offline/CHECK_CONNECTION"
export const ENABLE_SERVICE_OFFLINE = "offline/ENABLE_SERVICE"
export const DISABLE_SERVICE_OFFLINE = "offline/DISABLE_SERVICE"
export const QUEUE_REQUEST = 'offline/QUEUE_REQUEST'
export const UNQUEUE_REQUEST = 'offline/UNQUEUE_REQUEST'

export const checkConnection = () => ({
   type: CHECK_CONNECTION
})

export const enableServiceOffline = id => ({
   type: ENABLE_SERVICE_OFFLINE,
   payload: id
})

export const disableServiceOffline = id => ({
   type: DISABLE_SERVICE_OFFLINE,
   payload: id
})

export const queueRequest = data => ({
	type: QUEUE_REQUEST,
	payload: data
})

export const unqueueRequest = key => ({
	type: UNQUEUE_REQUEST,
	payload: key
})