import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { Divider, Tooltip } from 'antd'

import ReviewLogbookTable from '../ReviewLogbooks/partials/ReviewLogbookTable'

export default function ReviewLogbookTabOffline({ serviceReview, service }) {
    const [reviewLogbooks, setReviewLogbooks] = useState(null)

    useEffect(() => {
        !reviewLogbooks && setReviewLogbooks(serviceReview.review_logbooks)
    }, [ reviewLogbooks, serviceReview ])

    if(!reviewLogbooks) return <Spinner animation='border' />

    return (
        <React.Fragment>
            <h6 className='mt-10 mb-15 text-center'>Bitacoras de Inspección</h6>
            <ReviewLogbookTable 
                reviewLogbooks={reviewLogbooks}
                reload={() => setReviewLogbooks(null)}
                serviceReview={serviceReview}
                serviceId={service.id}
            />
            <Tooltip title="Requiere conexión a internet">
                <Button color='primary' outline disabled>
                    Agregar Nueva Bitacora
                </Button>
            </Tooltip>
            <Divider type='vertical' />
            <Tooltip title="Requiere conexión a internet">
                <Button color='primary' outline disabled>
                    Notificar Director
                </Button>
            </Tooltip>
        </React.Fragment>
    )
}