import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ReportsDashboard from './ReportsDashboard'

export default function Reports() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/reports" component={ReportsDashboard} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}