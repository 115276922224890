import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServiceInspectorForm from './ServiceInspectorForm'

import { storeServiceInspector } from '../services'
import { handleError } from '../../../helpers'

export default function NewServiceInspectorModal({ visible, onClose, service, reload }) {
	const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
		defaultValues: {
			service_value: 0,
			trip_expenses: 0,
			observations: '- Confirmar con el Cliente para la elaboración del Plan de Inspección: confirmar número de tanques, capacidad de llenado para el día del servicio, hora de inicio del servicio, especificaciones de tanques, tuberías, desfogues, entre otras, importantes para la prestación del servicio.\n\n- Dar pleno cumplimiento con el procedimiento PN13 Procedimiento de Pruebas de hermeticidad, procedimiento de la literales m y n del numeral 5.6.2 de la Resolución 40198 de 2021.\n\n- Revisar el DN10 Documento de Criterios de tiempo para la elaboración del Plan de Inspección correspondiente'
		}
	})
	const [userSelected, setUserSelected] = useState([])
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeServiceInspector({ ...values, service_id: service.id, user_id: userSelected.value })
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Asignar Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceInspectorForm 
							register={register} 
							errors={errors}
							watch={watch}
							setValue={setValue}
							userSelected={userSelected}
							setUserSelected={setUserSelected}
							businessScope={service.business_scope}
							service={service}
						/>
						<Button color="primary" type='submit' className='mt-10' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Asignar Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}