import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Empty, Tooltip, Popconfirm, Table, message, Divider } from 'antd'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faFileImage, faLink } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import TicketNotesModal from '../../TicketNotes/partials/TicketNotesModal'

import { updateTicket } from '../services'
import { handleError } from '../../../helpers'

export default function TicketsTable({ tickets, reload }) {
	const [ticketNotesModal, setTicketNotesModal] = useState(null)

	const onToggleResolved = (ticket) => {
		updateTicket(ticket.id, {
			resolved_at: ticket.resolved_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{ 
			title: 'Fecha de envío',
			dataIndex: 'created_at',
			render: t => `${moment(t).format('YYYY-MM-DD')} (${moment(t).fromNow()})`
		},
		{
			title: 'Empresa',
			dataIndex: 'company',
			render: t => t.name
		},
		{
			title: 'Categoría',
			dataIndex: 'ticket_type',
			render: t => t.category_name
		},
		{
			title: 'Tipo de Solicitud',
			dataIndex: 'ticket_type',
			render: t => t.name
		},
		{
			title: 'Comentarios',
			dataIndex: 'ticket_notes',
			render: t => `${t.length} comentarios`
		},
		{
			title: 'Estado',
			dataIndex: 'resolved_at',
			render: (t,r) => (
				<Tooltip title={t ? 'Marcar como abierto' : 'Marcar como resuelto'}>
					<Popconfirm
						title={t ? '¿Marcar como abierto nuevamente?' : '¿Marcar como resuelto?'}
						cancelText="Cancelar"
						okText="Confirmar"
						onConfirm={() => onToggleResolved(r)}
					>
						<Badge color={t ? 'success' : 'info'} style={{ cursor: 'pointer' }}>
							{ t ? 'Resuelto' : 'En Revisión' }
						</Badge>
					</Popconfirm>
				</Tooltip>
			)
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Comentarios" className='text-link'>
						<FontAwesomeIcon
							icon={faComments} 
							onClick={() => setTicketNotesModal(r)}
						/>
					</Tooltip>
					{ r.service_id && (
						<React.Fragment>
							<Divider type="vertical" />
							<Link to={`/services/${r.service_id}`}>
								<Tooltip title="Abrir Servicio" className='text-link'>
									<FontAwesomeIcon icon={faLink} />
								</Tooltip>
							</Link>							
						</React.Fragment>
					)}
					{ r.service_report_id && (
						<React.Fragment>
							<Divider type="vertical" />
							<Link to={`/service_reports/${r.service_report_id}`}>
								<Tooltip title="Abrir Informe" className='text-link'>
									<FontAwesomeIcon icon={faLink} />
								</Tooltip>
							</Link>							
						</React.Fragment>
					)}
					{ r.attachment_uri && (
						<React.Fragment>
							<Divider type="vertical" />
							<a href={r.attachment_uri} target='_blank' rel="noreferrer">
								<Tooltip title="Abrir Adjunto" className='text-link'>
									<FontAwesomeIcon icon={faFileImage} />
								</Tooltip>
							</a>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = (r) => (
		<React.Fragment>
			<p className='mb-0'><strong>Correo para notificaciones: </strong>{r.notify_email}</p>
			<p className='mb-0'><strong>Detalle de la solicitud: </strong>{r.observations}</p>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table
				dataSource={tickets}
				columns={columns}
				rowKey={r => r.id}
				loading={!tickets}
				locale={{ emptyText: <Empty description="No se han recibido novedades" /> }}
				expandable={{ expandedRowRender }}
				size="small"
			/>
			{ ticketNotesModal && (
				<TicketNotesModal
					visible
					onClose={() => setTicketNotesModal(null)}
					ticket={ticketNotesModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}