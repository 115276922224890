import { 
	SYNC_SERVICE,
	REMOVE_SERVICE,
	UPDATE_SERVICE_REVIEW,
	UPDATE_VERIFICATION_TASKS,
	DELETE_VERIFICATION_TASK
} from "./actions"
import moment from 'moment'

const INITIAL_STATE = {
	data: [],
	services_ids: [],
}

const servicesReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
		case SYNC_SERVICE:
			return { 
				...state, 
				data: [ 
					...state.data.filter(r => r.id !== action.payload.id), 
					{ ...action.payload, sync_at: moment().format('DD/MMM/YY h:mm A') }
				],
				services_ids: [ ...state.services_ids, action.payload.id ].filter((v, i, self) => self.indexOf(v) === i)
			}

		case REMOVE_SERVICE:
			return { 
				...state, 
				data: [ ...state.data.filter(r => r.id !== action.payload) ],
				services_ids: [ ...state.services_ids.filter(r => r !== action.payload) ]
			}

		case UPDATE_SERVICE_REVIEW:
			let service = state.data.find(r => r.id === action.serviceId)
			return {
				...state,
				data: [
					...state.data.filter(r => r.id !== action.serviceId),
					{
						...service,
						service_inspector: {
							...service.service_inspector,
							service_plan: {
								...service.service_inspector.service_plan,
								service_review: {
									...service.service_inspector.service_plan.service_review,
									...action.payload
								}
							}
						}
					}
				].sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1)
			}
		
		case UPDATE_VERIFICATION_TASKS:
			let service2 = state.data.find(r => r.id === action.serviceId)
			return {
				...state,
				data: [
					...state.data.filter(r => r.id !== action.serviceId),
					{
						...service2,
						verification_list: {
							...service2.verification_list,
							verification_tasks: [
								...service2.verification_list.verification_tasks.filter(r => r.task_template_id !== action.payload.task_template_id),
								{
									...action.payload,
									status: getStatus(action.payload)
								}
							].sort((a, b) => (parseInt(a.task_template.sku) > parseInt(b.task_template.sku)) ? 1 : -1)
						}
					}
				]
			}
		
		case DELETE_VERIFICATION_TASK:
			let service3 = state.data.find(r => r.id === action.serviceId)
			return {
				...state,
				data: [
					...state.data.filter(r => r.id !== action.serviceId),
					{
						...service3,
						verification_list: {
							...service3.verification_list,
							verification_tasks: [
								...service3.verification_list.verification_tasks.filter(r => r.task_template_id !== action.payload.task_template_id)
							]
						}
					}
				]
			}

      default:
         return { ...state }
   }
}

const getStatus = verificationTask => {
	if(!verificationTask.is_active)
		return 'NA - No Aplica'

	if(verificationTask.is_compliant) 
		return 'C - Conforme'

	if(!verificationTask.is_require)
		return 'NCP - No Conformidad con tiempo en la resolución';
	  
	return 'NC - No Conforme'
}

export default servicesReducer