import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'
import { Redirect } from 'react-router'
import { Result, Tabs } from 'antd'

import ServiceProfileDetails from '../Services/partials/ServiceProfileDetails'

import ServiceReviewTab from '../ServiceReviews/ServiceReviewTab'
import ServiceResolutionTab from '../Services/ServiceResolutionTab'
import ReviewLogbookTabOffline from './ReviewLogbookTabOffline'
import VerificationListTabOffline from './VerificationListTabOffline'
import NonCompliantTabOffline from './NonCompliantTabOffline'

export default function ServiceProfileOffline(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.match.params.id
	const service = useSelector(state => state.services.data.filter(r => r.id === parseInt(id))[0])
	const [currentTab, setCurrentTab] = useState('acta')
	const [filters, setFilters] = useState({ 
		onlyUncompliant: false,
		pendingTasks: {
			periods: []
		}
	})

	if(isConnected) return <Redirect to={`/services/${id}`} />

	if(!service) return <Result status="warning" title="El servicio no se encuentra disponible para Offline" />

	const serviceInspector = service.service_inspector
	const servicePlan = serviceInspector && serviceInspector.service_plan
	const serviceReview = servicePlan && servicePlan.service_review 
	const nonCompliantReport = serviceReview && serviceReview.non_compliant_report 

	const reload = () => {}

	const tabProps = {
		service,
		reload,
		filters,
		setFilters
	}

	const generalItems = [
		{
			key: 'asignacion',
			label: '1. Asignación de Inspección',
			disabled: true,
		},
		{
			key: 'notificacion',
			label: '2. Notificación de Inspección',
			disabled: true,
		},
		{
			key: 'plan',
			label: '3. Plan de Inspección',
			disabled: true,
		},
		{
			key: 'acta',
			label: '4. Acta de Inspección',
			disabled: !servicePlan,
			children: <ServiceReviewTab {...tabProps} />,
		},
	]

	const inspectionItems =  generalItems.concat([
		{
			key: 'lista',
			label: '5. Lista de Verificación',
			disabled: !serviceReview,
			children: <VerificationListTabOffline {...tabProps} />,
		},
		{
			key: 'evidencias',
			label: service.type === 'Inicial' ? '6. Evidencias Relacionadas' : '6. Evidencias No Conformidades',
			disabled: true,
		},
		{
			key: 'noconformidades',
			label: '7. Reporte No Conformidades',
			disabled: !(serviceReview && service.verification_list),
			children: currentTab === 'noconformidades' && <NonCompliantTabOffline {...tabProps} />,
		},
		{
			key: 'informe',
			label: '8. Resultado de Inspección',
			disabled: !(nonCompliantReport && service.verification_list),
			children: <ServiceResolutionTab {...tabProps} />,
		},
		{
			key: 'retrynotice',
			label: '9. Carta Inspección de Cierre',
			disabled: true
		}
	])

	const testItems = generalItems.concat([
		{
			key: 'bitacora',
			label: '5. Bitacoras de Inspección',
			disabled: !serviceReview,
			children: <ReviewLogbookTabOffline {...tabProps} serviceReview={serviceReview} />,
		},
		{
			key: 'reportEvidence',
			label: '6. Evidencia Relacionada',
			disabled: true,
		},
		{
			key: 'securityLogs',
			label: '7. Registros de Seguridad',
			disabled: true,
		}
	])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Servicio de Inspección #{service.id} - OFFLINE</CardTitle>
					<CardSubtitle>Propuesta: {service.proposal.sku} | Inspección {service.type}</CardSubtitle>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<Tabs 
						tabPosition='left' 
						type="card" 
						activeKey={currentTab} 
						onChange={key => setCurrentTab(key)}
						items={service.business_scope === 'inspeccion' ? inspectionItems : testItems}
					/>
				</CardBody>
			</Card>
			<ServiceProfileDetails service={service} />
		</React.Fragment>
	)
}