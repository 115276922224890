import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'

export default function DECISION_NOTICE_TEMPLATE_PRU01({ data }) {
	const serviceReview = data.service_veredict.service_report.service_review
	const proposal = serviceReview.service_plan.service_inspector.service.proposal
	const company = proposal.inquiry.branch_spec.branch.company
	const certificate = data.service_veredict.certificate

	return (
		<Document>
			<DocumentHeader 
				title="COMUNICADO DECISIÓN PROCESO DE INSPECCIÓN – PRUEBAS DE HERMETICIDAD"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
				logoSrc={logo}
			/>
			<p className='text-right mb-10'>Bogotá D.C., {moment(data.published_at).format('DD MMMM YYYY')}</p>
			<p className='mb-5'>Señores:</p>
			<p className='mb-5'><strong>{company.legal_owner_name}</strong></p>
			<p className='mb-5'><strong>INSTALACIÓN {proposal.inquiry.branch_spec.branch.name}</strong></p>
			<p className='mb-5'>{proposal.inquiry.branch_spec.branch.address_line}</p>
			<p className='mb-20'>{proposal.inquiry.branch_spec.branch.address_city}</p>
			<p className='text-right mb-20'><strong>Ref: Decisión Proceso de inspección {proposal.sku}</strong></p>
			<p className='mb-20' style={{ whiteSpace:'pre-line' }}>
				Una vez realizado el  proceso de inspección – prueba de hermeticidad a tanques y tuberías de la instalación {proposal.inquiry.branch_spec.branch.name}, DEWAR, como Organismo de inspección se hace entrega del informe {serviceReview.service_plan.service_inspector.sku}

				{ certificate && `y del certificado de inspección ${certificate.sku_formated} como resultado del  proceso de inspección que hace parte del acuerdo legalmente ejecutable, es por esto que para mantener el certificado es de obligatorio cumplimiento de las condiciones de la propuesta comercial y el reglamento de inspección previamente aceptado por ustedes. \n\n En el certificado de inspección se encuentran los tanques y tuberías que el día de la inspección pasaron las pruebas de hermeticidad y se declararon conformes de acuerdo con el Reglamento técnico. Las fechas consignadas en el certificado de inspección corresponden a los días en que se realizaron las pruebas, y el día que el certificado es emitido.`}
			</p>
			<p className='mb-20'>Es importante tener presente que las pruebas de hermeticidad a tanques y tuberías deben realizarse y obtener su certificado de acuerdo con los tiempos establecidos en la Resolución 40198 de 2021 numeral 5.6.2 literales m y n  donde se establece que  se deberán realizar estas  pruebas de hermeticidad mínimo una vez cada 2 años.</p>
			<p className='mb-20'>La instalación debe realizar la gestión de radicación del informe {certificate && `y certificado`} de inspección de las pruebas de hermeticidad a tanques y tuberías ante la Dirección de Hidrocarburos del Ministerio de Minas y Energía en cumplimiento de la Resolución 40198 de 2021 numeral 5.6.2 literal p en el cual se estable que los resultados de las pruebas realizadas deberán ser remitidos por los agentes de la cadena a la Dirección de Hidrocarburos.  </p>
			<p className='mb-20'>Les recordamos que en el Reglamento de Inspección podrá consultar las condiciones y requisitos específicos para publicar y mantener el certificado de inspección otorgado.</p>
			<p className='mb-40'>Ampliaremos la información que sea de su interés </p>
			<p className='mb-40'>Cordialmente,</p>
			<p className='mt-40 mb-5'>{data.service_veredict.service_report.director_name}</p>
			<p className='mb-5'>Directora Técnica.</p>
			<p className='mb-5'>Dewar S.A.S.</p>
			<p className='mt-30 text-center'>Carrera 61 No. 96 A – 23 Oficina 201 Barrio Rincón de los Andes. Bogotá D.C. – Colombia. <br /> Celular  3163702077 organismo@dewar.com.co – www.dewar.com.co</p>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px 40px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 15px;
	}
`